// Packages
import { useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import MenuItem from "@mui/material/MenuItem";
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WalletTabs(props) {
  const { sx } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card variant="outlined" sx={sx}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} sx={{
          '& button': {
            textTransform: 'none',
          },
        }}>
          <Tab label="Transfer" {...a11yProps(0)} />
          <Tab label="Transactions" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell>TXN</TableCell>
                <TableCell>Date Time (UTC)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
              '& p': {
                fontSize: 14,
              },
            }}>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Out" color="error" size="small" />
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    0xeBD2...9Cf8
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    0xF524...097c
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    0.2 BNB
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    Jan 3 12:17:11 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="In" color="success" size="small" />
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    0xF524...097c
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    0xeBD2...9Cf8
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    12 BNB
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    Jan 3 12:17:02 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Out" color="error" size="small" />
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    0xeBD2...9Cf8
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    0xF524...097c
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    0.2 BNB
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    Jan 3 12:17:11 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="In" color="success" size="small" />
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    0xF524...097c
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    0xeBD2...9Cf8
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    12 BNB
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    Jan 3 12:17:02 AM
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box sx={{
          textAlign: 'right',
        }}>
          <TextField
            select
            label="Filter by token"
            defaultValue="all"
            size="small"
            sx={{
              width: '200px',
              textAlign: 'left',
            }}
          >
            <MenuItem value="all">
              All Token
            </MenuItem>
          </TextField>
        </Box>

        <TableContainer sx={{
          mt: 2,
        }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">USD</TableCell>
                <TableCell align="right">BNB</TableCell>
                <TableCell align="right">Token</TableCell>
                <TableCell>TXN</TableCell>
                <TableCell>Date Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
              '& p': {
                fontSize: 14,
              },
            }}>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Sell" color="error" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    $83.02
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    0.3366 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    721.090 PAI
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    Jan 3 12:17:11 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Approve" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    $0.06
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    0.000225 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  Max PAI
                </TableCell>
                <TableCell>
                  <Typography>
                    c1be1d
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Jan 3 12:17:02 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Buy" color="success" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    $206.20
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    0.8342 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    1,227.000 PAI
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    c7d3d9
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    Jan 2 04:30:07 PM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Sell" color="error" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    $83.02
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    0.3366 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="error.main">
                    721.090 CAKE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    cbcb13
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="error.main">
                    Jan 3 12:17:11 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Approve" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    $0.06
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    0.000225 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  Max CAKE
                </TableCell>
                <TableCell>
                  <Typography>
                    c1be1d
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Jan 3 12:17:02 AM
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Chip label="Buy" color="success" size="small" />
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    $206.20
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    0.8342 BNB
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="success.main">
                    1,227.000 CAKE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    c7d3d9
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    Jan 2 04:30:07 PM
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Card>
  );
}
