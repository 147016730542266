export async function dexscreenerGetTokenInfo(tokenAddress) {
  const
    url = `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    return error;
  }
}