// Pai Telegram Connection GET
export async function PaiTelegramConnectionsGet(walletAddress, signedData) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-telegram-connections`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// Pai Telegram Connection Disconnect
export async function PaiTelegramConnectionsDisconnect(walletAddress, signedData) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-telegram-connections/disconnect`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
