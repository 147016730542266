// Packages
import { configureStore } from '@reduxjs/toolkit';

// Reducer
import bnbReducer from './bnbSlice';
import connectedWalletReducer from './connectedWalletSlice';
import paiReducer from './paiSlice';



export const store = configureStore({
  reducer: {
    bnb: bnbReducer,
    connectedWallet: connectedWalletReducer,
    pai: paiReducer,
  },
});
