// Packages
import { useParams } from "react-router-dom";

// Material UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Icons
import CachedIcon from '@mui/icons-material/Cached';



export default function Chart() {
  const {
    tokenAddress,
  } = useParams();

  const reloadChart = () => {
    if (document.getElementById('chart')) {
      document.getElementById('chart').src = document.getElementById('chart').src;
    }
  };

  return (
    <Grid container spacing={2} sx={{
      mt: 0,

      '& .xs-no-pt': {
        pt: {
          xs: '0 !important',
          sm: '16px !important',
        },
      },
    }}>
      <Grid item xs={12} md={6}>
        <Card variant="outlined" sx={{
          borderBottom: 'none',
        }}>
          <CardContent>
            <Typography variant="body2" sx={{
              fontWeight: 'bold',
              display: 'inline-block',
            }}>
              Chart
            </Typography>

            <Button variant="outlined" size="small" startIcon={<CachedIcon />} sx={{
              ml: 2,
            }}
              onClick={reloadChart}
            >
              Reload
            </Button>
          </CardContent>

          <Divider />

          <iframe
            id="chart"
            title="chart"
            width="100%"
            height="450px"
            style={{
              border: 'none',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            src={`https://dexscreener.com/bsc/${tokenAddress}?embed=1&trades=0&info=0`}
          />
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <iframe
            title="trades"
            width="100%"
            height="500px"
            style={{
              border: 'none',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            src={`https://coinbrain.com/embed/bnb-${tokenAddress}?theme=light&padding=16&chart=0&trades=1`}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
