// Packages
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// ACE
import 'ace-builds/src-noconflict/ace';
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-terminal";
import AceEditor from "react-ace";

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

// Library
import {
  getCurrentVNDatetimeWithSecondDigits,
} from 'libraries/Datetime';
import {
  getTokenAnalyse,
} from 'libraries/Web3';

// Services
import {
  PaiBotWalletsTrade,
} from 'services/PaiBotWallets';

// Store
import {
  selectAddress,
  selectSignedData,
} from 'app/connectedWalletSlice';



export default function Sniper() {
  const { enqueueSnackbar } = useSnackbar();

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const snipingRef = useRef('');
  const [sniping, setSniping] = useState(false);

  const [CA, setCA] = useState('');

  const valueRef = useRef('');
  const [value, setValue] = useState('');

  const changeValue = newCA => {
    // Turn off sniping
    setSniping(false);

    setCA(newCA);

    if (newCA) {
      appendText(`CA was changed to ${newCA}`);
    } else {
      appendText(`CA was deleted`);
    }
  }

  const onSubmit = () => {
    if (!CA) {
      appendText(`Please enter a CA to snipe`);
      return false;
    }

    // Toggle sniping
    setSniping(!sniping);
  }

  const appendText = text => {
    let newValue;

    if (valueRef.current) {
      newValue = valueRef.current + '\n\n' + text;
    } else {
      newValue = text;
    }

    setValue(newValue);
  }

  const buy = async (wallet, buyGasUsed) => {
    const
      PaiBotWallets = await PaiBotWalletsTrade(connectedWalletAddress, connectedWalletSignedData, "buy", wallet, CA, 0.05, buyGasUsed);

    if (PaiBotWallets.error && PaiBotWallets.error.message) {
      enqueueSnackbar(PaiBotWallets.error.message, { variant: 'warning' });
    } else if (PaiBotWallets.success && PaiBotWallets.success.message) {
      enqueueSnackbar(PaiBotWallets.success.message);
    }
  }

  const scan = async () => {
    if (!snipingRef.current) {
      return false;
    }

    const analyse = await getTokenAnalyse(CA); // Make sure LP was added
    const error = analyse?.error;

    appendText(`${getCurrentVNDatetimeWithSecondDigits()}: Error: ${error}`);

    if (error) {
      return scan();
    }

    // Turn off sniping
    setSniping(false);

    // Snip
    appendText(`${getCurrentVNDatetimeWithSecondDigits()}: Snipping`);

    const buyGasUsed = analyse?.buyGasUsed;
    [
      '0xBD3c86d730fe1283Ca60B6fA0741739EE270D431',
      '0x5Ba006c63850f0920C7Cc8d9904dFFEC86f8f8fe',
      '0xd0ab6E0fA1Bd840B509C13d26195167F2EfAFFe9',
    ].forEach(wallet => {
      buy(wallet, buyGasUsed);
    });

    appendText(JSON.stringify(analyse, null, 4));
  }

  // Ref
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  useEffect(() => {
    snipingRef.current = sniping;
  }, [sniping]);

  useEffect(() => {
    if (sniping) {
      appendText(`Turned on sniper`);

      appendText(`${getCurrentVNDatetimeWithSecondDigits()}: Check CA: ${CA}`);

      scan();
    } else {
      appendText(`Turned off sniper`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sniping]);

  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,

      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    }}>
      <Container>

        <Card variant="outlined" sx={{
          mt: 2,
        }}>
          <CardContent sx={{
            display: {
              sm: 'flex',
            },
          }}>
            <Box sx={{
              flexGrow: 1,
            }}>
              <Typography variant="body2">
                <Box component="span" sx={{
                  fontWeight: 'bold',
                }}>
                  Profit AI Sniper
                </Box>
                <Box component="span" sx={{
                  ml: 1,
                  color: 'gray',
                  fontStyle: 'italic',
                }}>
                  Status:
                  {sniping && ' true'}
                  {!sniping && '  false'}
                </Box>
              </Typography>
            </Box>

            <Paper
              variant="outlined"
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              sx={{
                display: 'flex',
              }}
            >
              <InputBase
                size="small"
                placeholder="Enter a CA to snipe"
                value={CA}
                onChange={(e) => { changeValue(e.target.value) }}
                sx={{
                  flex: 1,

                  "& input": {
                    fontSize: ".875rem",
                    width: 340,
                    height: {
                      md: 20,
                    },
                    p: {
                      xs: '8.5px 14px',
                      md: '4px 10px',
                    },
                  },
                }}
              />

              <IconButton
                type="reset"
                onClick={() => { changeValue('') }}
                size="small"
              >
                <ClearIcon />
              </IconButton>

              <Divider orientation="vertical" sx={{
                height: 24,
                margin: {
                  xs: '8px 4px',
                  md: '4px',
                },
              }} />
              <IconButton
                type="submit"
                size="small"
                sx={{
                  my: {
                    xs: 0.2,
                    md: 0,
                  },
                }}
              >
                {!sniping && <PlayArrowIcon />}
                {sniping && <StopIcon />}
              </IconButton>
            </Paper>
          </CardContent>

          <Divider />

          <Box sx={{
            p: 2,
          }}>

            <AceEditor
              mode="sh"
              theme="terminal"
              readOnly={true}
              value={value}
              name="ProfitAISniper"
              editorProps={{ $blockScrolling: true }}
              width="100%"
            />

          </Box>
        </Card>

      </Container>
    </Box>
  );
}
