// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';



export default function CrazyDev() {
  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,

      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    }}>
      <Container>

        <Card variant="outlined" sx={{
          mt: 2,
        }}>
          <CardContent sx={{
            display: 'flex',
          }}>
            <Box sx={{
              flexGrow: 1,
            }}>
              <Typography variant="body2" sx={{
                fontWeight: 'bold',
              }}>
                Name: Crazy Dev
              </Typography>
              <Typography variant="body2">
                Wallet address: 0xf000116d61A11Ee15cc25AE56F03623539ABBf6F
              </Typography>
            </Box>

            <Box>
              123
            </Box>
          </CardContent>

          <Divider />

          <Box sx={{
            p: 2,
          }}>

            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                }}>
                  Token Name: SONGOKU
                </Typography>
                <Typography variant="body2">
                  Token address: 0xf000116d61A11Ee15cc25AE56F03623539ABBf6F
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                p: 2,
              }}>
                <Typography variant="body2">
                  • Add LP: <strong>10 BNB</strong>
                </Typography>
                <Typography variant="body2">
                  • Approve PancakeSwap V3
                </Typography>
              </Box>
            </Card>

          </Box>
        </Card>

      </Container>
    </Box>
  );
}
