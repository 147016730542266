// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TokenIcon from '@mui/icons-material/Token';
import WalletIcon from '@mui/icons-material/Wallet';

// Components
import BiggestProfits from 'components/pages/home/BiggestProfits';
import BotStatistics from 'components/pages/home/BotStatistics';
import GroupTabs from 'components/pages/home/GroupTabs';
import LatestProfits from 'components/pages/home/LatestProfits';

// Const
const ICON_SIZE = "20px";

export default function Home() {
  return (
    <Box component="main" sx={{
      pb: 6,
    }}>
      <Container sx={{
        mt: 2,

        '& .title, & .grey': {
          color: "#77838f",
          fontSize: 13,
        },

        '& .number': {
          color: "#1e2022",
          fontSize: 15,
        },
      }}>

        {/* Bot Statistics */}
        <BotStatistics />
        {/* Bot Statistics */}

        {/* New Groups */}
        <Box sx={{
          mt: 2,
        }}>
          <GroupTabs />
        </Box>
        {/* New Groups */}

        <Box sx={{
          mt: 2,
          display: 'none', // WIP
        }}>
          <Stack spacing={1} direction="row" sx={{
            '& button': {
              boxShadow: 'none',
            }
          }}>
            <Button variant="contained" size="small">1 Day</Button>
            <Button variant="outlined" size="small">3 Days</Button>
            <Button variant="outlined" size="small">7 Days</Button>
          </Stack>

          <Typography variant="body2" sx={{
            mt: 2,
          }}>
            Today
          </Typography>
        </Box>

        <Paper variant="outlined" sx={{
          mt: 2,
          p: 2,
          display: 'none', // WIP
        }}>
          <Grid container spacing={2} sx={{
            '& .card-content': {
              display: 'flex',

              '& .icon-wrap': {
                mt: 1,
                width: ICON_SIZE,
                height: ICON_SIZE,

                '& .MuiSvgIcon-root': {
                  width: ICON_SIZE,
                  height: ICON_SIZE,
                },
              },

              '& .info': {
                textAlign: 'left',
                ml: 1,
                flex: 1,
              },
            },

            '& hr': {
              my: 3,
            },
          }}>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-content">
                <Box className="icon-wrap">
                  <AttachMoneyIcon />
                </Box>
                <Box className="info">
                  <Typography variant="body2" className="title">
                    TOTAL PROFITS
                  </Typography>
                  <Typography variant="body2" className="numnber">
                    $27,988,016 <span className="grey">(1,224 BNB)</span>
                  </Typography>
                </Box>
              </Box>

              <Divider />

              <Box className="card-content">
                <Box className="icon-wrap">
                  <TokenIcon />
                </Box>
                <Box className="info">
                  <Typography variant="body2" className="title">
                    TOTAL TOKENS
                  </Typography>
                  <Typography variant="body2" className="numnber">
                    125
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{
                display: {
                  md: 'none',
                },
              }} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{
              pt: {
                xs: '0 !important',
                sm: '16px !important',
              },
            }}>
              <Box className="card-content">
                <Box className="icon-wrap">
                  <WalletIcon />
                </Box>
                <Box className="info">
                  <Typography variant="body2" className="title">
                    TOTAL WALLETS
                  </Typography>
                  <Typography variant="body2" className="numnber">
                    3,241
                  </Typography>
                </Box>
              </Box>

              <Divider />

              <Box className="card-content">
                <Box className="icon-wrap">
                  <ReceiptIcon />
                </Box>
                <Box className="info">
                  <Typography variant="body2" className="title">
                    TOTAL TRANSACTIONS
                  </Typography>
                  <Typography variant="body2" className="numnber">
                    481,623
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{
                display: {
                  md: 'none',
                },
              }} />
            </Grid>

            <Grid item xs={12} sm={12} md={4} sx={{
              pt: {
                xs: '0 !important',
                sm: '16px !important',
              },
            }}>
              <Typography variant="body2" className="title">
                TRANSACTION HISTORY
              </Typography>
              <Typography variant="body2" className="grey">
                Comming soon...
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Divider sx={{
          my: 6,
        }} />

        <Box sx={{
          display: 'flex',
        }}>
          <Box sx={{
            flex: 1,
          }}>
            <Typography variant="body2" sx={{
              lineHeight: '30px',
              fontSize: 21,
            }}>
              Profit List
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{
          mt: 0,
        }}>
          <Grid item xs={12} md={6}>
            <BiggestProfits />
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestProfits />
          </Grid>
        </Grid>
      </Container>

    </Box>
  );
}
