export function getShortAddress(address) {
  if (address) {
    return `${address.substr(0, 6)}...${address.substr(-4)}`;
  } else {
    return "";
  }
}

export function formatToken(
  token,
  minimumFractionDigits = 2,
  maximumFractionDigits
) {
  if (token === 0) {
    return token;
  }

  let opt = {
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  };

  if (maximumFractionDigits !== null) {
    opt = {
      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    };
  }

  let formatter = new Intl.NumberFormat("en-US", opt);

  return token ? formatter.format(token) : "";
}

export function formatTokenDynamic(x, extend) {
  if (!x) {
    return 0;
  }

  const temp = x - Math.floor(x);

  if (extend === undefined) {
    extend = 3;
  }

  let m = -Math.floor(Math.log10(temp) + 1);

  if (m >= 17) {
    m = 17;
  }

  return formatToken(x, m + extend, m + extend);
}

export function formatDate(timestamp) {
  return new Intl.DateTimeFormat("default", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    // timeZoneName: 'short',
  }).format(new Date(timestamp * 1000));
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
