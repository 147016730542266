// Packages
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Components
import ProfitItem from 'components/pages/token/ProfitItem';

// Services
import {
  PAIProfitsGet,
} from 'services/PAIProfits';

export default function LatestProfits() {
  const { tokenAddress } = useParams();

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [latestProfits, setLatestProfits] = useState([]);

  const fetchLatestProfits = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "timestamp DESC",
          "limit": 10,
          "where": {
            "tokenAddress": tokenAddress
          }
        },
        PAIProfits = await PAIProfitsGet(opt);

      if (PAIProfits.error) {
        // Catch error and set error message
        setError(PAIProfits.error);
      } else {
        setLatestProfits(PAIProfits);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchLatestProfits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress]);

  if (error) {
    return (
      <Box sx={{
        mt: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  return (
    <Box>
      {fetching &&
        <Box sx={{
          py: 6,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Box>
      }

      {!fetching &&
        <Box>
          {/* Empty */}
          {latestProfits.length === 0 &&
            <Box sx={{
              py: 6,
              textAlign: 'center',
            }}>
              Not found any biggest profits.
            </Box>
          }

          {latestProfits.length > 0 &&
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Wallet</TableCell>
                    <TableCell>Total profit</TableCell>
                    <TableCell align="right">Total bought</TableCell>
                    <TableCell align="right">Total sold</TableCell>
                    <TableCell align="right">Total fee</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {latestProfits.map((biggestProfit) => (
                    <ProfitItem key={biggestProfit.id} profit={biggestProfit} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      }
    </Box>
  );
}
