// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Components
import ProfitItem from 'components/pages/home/ProfitItem';

// Services
import {
  PAIProfitsGet,
} from 'services/PAIProfits';

// Library
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectIsConnected,
  selectAddress,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';



export default function BiggestProfits() {
  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [biggestProfits, setBiggestProfits] = useState([]);

  const fetchBiggestProfits = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "realProfit DESC",
          "limit": 10,
          // "where": {
          //   "timestamp": {
          //     "gt": Date.now() - 1000 * 60 * 60 * 24 // 1 day
          //   }
          // }
        },
        PAIProfits = await PAIProfitsGet(opt);

      if (PAIProfits.error) {
        // Catch error and set error message
        setError(PAIProfits.error);
      } else {
        setBiggestProfits(PAIProfits);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchBiggestProfits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Box sx={{
        mt: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body2" sx={{
          fontWeight: 'bold',
          display: 'inline-block',
        }}>
          Biggest Profits
        </Typography>
        <Chip label="PRO" color="primary" size="small" sx={{
          ml: 1,
        }} />
      </CardContent>

      <Divider />

      {fetching &&
        <Box sx={{
          py: 6,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Box>
      }

      <CardContent sx={{
        '& hr': {
          my: 2,
        },
      }}>
        {!fetching &&
          <Box>
            {/* Empty */}
            {biggestProfits.length === 0 &&
              <Box sx={{
                py: 6,
                textAlign: 'center',
              }}>
                Not found any biggest profits.
              </Box>
            }

            {biggestProfits.length > 0 &&
              biggestProfits.map((biggestProfit) => (
                <ProfitItem key={biggestProfit.id} isProAccount={_isProAccount()} profits={biggestProfits} profit={biggestProfit} />
              ))
            }
          </Box>
        }

      </CardContent>

      {/* WIP
      <Divider />

      <CardActions sx={{
        p: 2,
      }}>
        <Button variant="contained" size="small" sx={{
          width: '100%',
        }}>View all biggest profits [PRO]</Button>
      </CardActions>
      */}
    </Card>
  );
}
