// Packages
import { createSlice } from '@reduxjs/toolkit';



let initialState = {
  balance: 0,
  price: 0,
};

export const paiSlice = createSlice({
  name: 'pai',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Set balance
    setPaiBalance: (state, action) => {
      state.balance = action.payload;
    },

    // Set price
    setPaiPrice: (state, action) => {
      state.price = action.payload;
    },
  },
});

export const selectPaiBalance = (state) => state.pai.balance;
export const selectPaiPrice = (state) => state.pai.price;

export const { setPaiBalance, setPaiPrice } = paiSlice.actions;

export default paiSlice.reducer;
