// Packages
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

// Library
import {
  formatDate,
  formatToken,
  getShortAddress,
} from 'libraries/Common';

// Material UI
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Icons
import TokenIcon from '@mui/icons-material/Token';

// Services
import {
  TelegramGroupMembersGet,
} from 'services/Telegrams';



export default function GroupItem(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    latestAddedGroup,
  } = props;

  const time = (new Date(latestAddedGroup.date)).getTime() / 1000;



  const [fetching, setFetching] = useState();
  const [telegramGroupMembers, setTelegramGroupMembers] = useState();

  const fetchTelegramGroupMembers = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const
        TelegramGroupMembers = await TelegramGroupMembersGet(latestAddedGroup.tgId);

      if (TelegramGroupMembers.error) {
        // Catch error and set error message
        enqueueSnackbar(TelegramGroupMembers.error.message);
      } else if (TelegramGroupMembers.members) {
        setTelegramGroupMembers(TelegramGroupMembers.members);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchTelegramGroupMembers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Typography sx={{
          display: 'flex',
        }}>
          <TokenIcon sx={{
            fontSize: 16,
            mt: 0.2,
          }} />

          <Link title={`${latestAddedGroup.tokenName} (${latestAddedGroup.tokenSymbol})`} component={RouterLink} to={`/token/${latestAddedGroup.tokenAddress}`} sx={{
            paddingLeft: 1,
            textDecoration: 'none',
          }}>{latestAddedGroup.tokenName} ({latestAddedGroup.tokenSymbol})</Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <Link title={`${latestAddedGroup.tokenName} (${latestAddedGroup.tokenSymbol})`} component={RouterLink} to={`/token/${latestAddedGroup.tokenAddress}`} sx={{
            textDecoration: 'none',
          }}>
            {getShortAddress(latestAddedGroup.tokenAddress)}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {!latestAddedGroup.groupShareLink && latestAddedGroup.groupName}
          {latestAddedGroup.groupShareLink &&
            <Link title={latestAddedGroup.groupName} href={`https://t.me/${latestAddedGroup.groupShareLink}`} target="_blank" sx={{
              textDecoration: 'none',
            }}>
              {latestAddedGroup.groupName}
            </Link>
          }
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {telegramGroupMembers && formatToken(telegramGroupMembers, 0)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatDate(time)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
