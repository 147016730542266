export function getCurrentGMTDatetime() {
  return new Date().toGMTString();
}

export function getCurrentVNDatetimeWithSecondDigits() {
  return new Date().toLocaleString('en-GB', {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
  });
}