// Packages
import { Link as RouterLink } from "react-router-dom";
import { useState } from 'react';
import * as React from 'react';

// Library
import {
  formatToken,
  getShortAddress,
} from 'libraries/Common';

// Material UI
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';



export default function ProfitItem(props) {
  const {
    isProAccount,
    profit,
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {!isProAccount &&
          <React.Fragment>
            <Link title={getShortAddress(profit.wallet)} onClick={handleOpen} sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}>{getShortAddress(profit.wallet)}</Link>
            <Dialog
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>
                Pro function
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions. You can buy PAI now on <Link sx={{
                    fontWeight: 'bold',
                  }} href="https://pancakeswap.finance/swap?outputCurrency=0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32" target="_blank">PancakeSwap</Link>.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        }
        {isProAccount &&
          <Link title={profit.wallet} component={RouterLink} to={`/token/${profit.tokenAddress}/transactions/${profit.wallet}`} sx={{
            textDecoration: "none",
          }}>{getShortAddress(profit.wallet)}</Link>
        }
      </TableCell>
      <TableCell sx={{
        display: 'flex',
      }}>
        <Typography variant="body2" sx={{
          fontSize: 14,
          lineHeight: '24px',
          display: 'none', // WIP
        }} color="success.main">
          $29,682.71
        </Typography>
        <Chip label={`${formatToken(profit.realProfit, 3, 3)} BNB`} color="success" size="small" sx={{
          // ml: 1, // WIP
        }} />
      </TableCell>
      <TableCell align="right">
        🟢 {profit.totalBoughtTxn} Txn - {formatToken(profit.totalBoughtAmount, 3, 3)} BNB
      </TableCell>
      <TableCell align="right">
        🔴 {profit.totalSoldTxn} Txn - {formatToken(profit.totalSoldAmount, 3, 3)} BNB
      </TableCell>
      <TableCell align="right">
        🟡 {profit.totalFeeTxn} Txn - {formatToken(profit.totalFeeAmount, 3, 3)} BNB
      </TableCell>
    </TableRow>
  );
}
