// Packages
import { createSlice } from '@reduxjs/toolkit';



const signedData = localStorage.getItem('signedData');

let initialState = {
  isConnected: false,
  address: null,
  signedData: signedData,
};

export const connectedWalletSlice = createSlice({
  name: 'connectedWallet',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Login
    login: (state, action) => {
      const isConnected = action.payload.isConnected;
      const address = action.payload.address;

      state.isConnected = isConnected;
      state.address = address;
    },

    // Set Signed Data
    setSignedData: (state, action) => {
      state.signedData = action.payload;

      if (action.payload) {
        localStorage.setItem('signedData', action.payload);
      } else {
        localStorage.removeItem('signedData');
      }
    },
  },
});

export const selectIsConnected = (state) => state.connectedWallet.isConnected;
export const selectAddress = (state) => state.connectedWallet.address;
export const selectSignedData = (state) => state.connectedWallet.signedData;

export const { login, setSignedData } = connectedWalletSlice.actions;

export default connectedWalletSlice.reducer;
