// PAI Bot Token COUNT
export async function ProfitAiBotTokensCount(opt) {
  const
    where = opt ? encodeURIComponent(JSON.stringify(opt)) : null,
    url = where ? `${process.env.REACT_APP_API_URL}profit-ai-bot-tokens/count?where=${where}` : `${process.env.REACT_APP_API_URL}profit-ai-bot-tokens/count`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// PAI Bot Token GET
export async function ProfitAiBotTokensGet(opt) {
  const
    filter = encodeURIComponent(JSON.stringify(opt)),
    url = `${process.env.REACT_APP_API_URL}profit-ai-bot-tokens?filter=${filter}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}