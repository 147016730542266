// Packages
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as React from "react";

// Material UI
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

// Icons
import ClearIcon from "@mui/icons-material/Clear";

// Services
import { PaiBotWalletsTrade } from "services/PaiBotWallets";

// Store
import { selectAddress, selectSignedData } from "app/connectedWalletSlice";

// Library
import { getRandomArbitrary, formatToken } from "libraries/Common";

function WalletBuy(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { tokenAddress } = useParams();

  const { wallet, bnbBalance, amount, buyGasUsed } = props;

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [fetching, setFetching] = useState();
  const buy = async () => {
    // Check amount
    if (!amount) {
      enqueueSnackbar("Can not buy zero token", { variant: "warning" });
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const PaiBotWallets = await PaiBotWalletsTrade(
        connectedWalletAddress,
        connectedWalletSignedData,
        "buy",
        wallet,
        tokenAddress,
        amount,
        buyGasUsed
      );

      if (PaiBotWallets.error && PaiBotWallets.error.message) {
        enqueueSnackbar(PaiBotWallets.error.message, { variant: "warning" });
      } else if (PaiBotWallets.success && PaiBotWallets.success.message) {
        enqueueSnackbar(PaiBotWallets.success.message);
      }

      // Turn off fetching
      setFetching(false);
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      color="success"
      disabled={fetching || bnbBalance < amount}
      onClick={buy}
    >
      Buy {amount}
    </Button>
  );
}

function WalletBuyX(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { tokenAddress } = useParams();

  const [amount, setAmount] = useState("");

  const { wallet, bnbBalance, buyGasUsed } = props;

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [fetching, setFetching] = useState();
  const buy = async () => {
    // Check amount
    if (!amount) {
      enqueueSnackbar("Can not buy zero token", { variant: "warning" });
      return false;
    }
    if (amount <= 0) {
      enqueueSnackbar("Negative amount", { variant: "warning" });
      return false;
    }
    if (bnbBalance < amount) {
      enqueueSnackbar("Insufficient funds", { variant: "warning" });
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const PaiBotWallets = await PaiBotWalletsTrade(
        connectedWalletAddress,
        connectedWalletSignedData,
        "buy",
        wallet,
        tokenAddress,
        amount,
        buyGasUsed
      );

      if (PaiBotWallets.error && PaiBotWallets.error.message) {
        enqueueSnackbar(PaiBotWallets.error.message, { variant: "warning" });
      } else if (PaiBotWallets.success && PaiBotWallets.success.message) {
        enqueueSnackbar(PaiBotWallets.success.message);
      }

      // Turn off fetching
      setFetching(false);
    }
  };

  return (
    <Paper
      variant="outlined"
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        buy();
      }}
      sx={{
        display: "flex",
        p: {
          xs: 1,
          md: 0.5,
        },

        "& button": {
          width: "auto !important",
        },
      }}
    >
      <InputBase
        size="small"
        placeholder="0.03"
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value);
        }}
        sx={{
          flex: 1,

          "& input": {
            fontSize: ".875rem",
            p: "4px 10px",
          },
        }}
      />

      <IconButton
        type="reset"
        onClick={() => {
          setAmount("");
        }}
        size="small"
        sx={{
          mr: 1,
          "& svg": {
            widht: "20px",
            height: "20px",
          },
        }}
      >
        <ClearIcon />
      </IconButton>

      <Button
        variant="contained"
        size="small"
        color="success"
        disabled={fetching || !amount || amount <= 0 || bnbBalance < amount}
        onClick={buy}
      >
        Buy
      </Button>
    </Paper>
  );
}

function WalletBuyRandom(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { tokenAddress } = useParams();

  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  const { wallet, bnbBalance, buyGasUsed } = props;

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [fetching, setFetching] = useState();
  const buy = async () => {
    // Check min
    if (!min) {
      enqueueSnackbar("Can not buy zero token", { variant: "warning" });
      return false;
    }
    if (min <= 0) {
      enqueueSnackbar("Negative min", { variant: "warning" });
      return false;
    }
    if (bnbBalance < min) {
      enqueueSnackbar("Insufficient funds", { variant: "warning" });
      return false;
    }

    // Check max
    if (!max) {
      enqueueSnackbar("Can not buy zero token", { variant: "warning" });
      return false;
    }
    if (max <= 0) {
      enqueueSnackbar("Negative max", { variant: "warning" });
      return false;
    }
    if (bnbBalance < max) {
      enqueueSnackbar("Insufficient funds", { variant: "warning" });
      return false;
    }

    const amount = getRandomArbitrary(parseFloat(min), parseFloat(max));

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const PaiBotWallets = await PaiBotWalletsTrade(
        connectedWalletAddress,
        connectedWalletSignedData,
        "buy",
        wallet,
        tokenAddress,
        formatToken(amount, 5, 5),
        buyGasUsed
      );

      if (PaiBotWallets.error && PaiBotWallets.error.message) {
        enqueueSnackbar(PaiBotWallets.error.message, { variant: "warning" });
      } else if (PaiBotWallets.success && PaiBotWallets.success.message) {
        enqueueSnackbar(PaiBotWallets.success.message);
      }

      // Turn off fetching
      setFetching(false);
    }
  };

  return (
    <Paper
      variant="outlined"
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        buy();
      }}
      sx={{
        display: "flex",
        p: {
          xs: 1,
          md: 0.5,
        },

        "& button": {
          width: "auto !important",
        },
      }}
    >
      <InputBase
        size="small"
        placeholder="Min"
        value={min}
        onChange={(e) => {
          setMin(e.target.value);
        }}
        sx={{
          flex: 1,

          "& input": {
            fontSize: ".875rem",
            p: "4px 10px",
          },
        }}
      />

      <IconButton
        type="reset"
        onClick={() => {
          setMin("");
        }}
        size="small"
        sx={{
          mr: 1,
          "& svg": {
            widht: "20px",
            height: "20px",
          },
        }}
      >
        <ClearIcon />
      </IconButton>

      <Button
        variant="contained"
        size="small"
        color="success"
        disabled={
          fetching ||
          !min ||
          min <= 0 ||
          bnbBalance < min ||
          !max ||
          max <= 0 ||
          bnbBalance < max
        }
        onClick={buy}
      >
        Random
      </Button>

      <InputBase
        size="small"
        placeholder="Max"
        value={max}
        onChange={(e) => {
          setMax(e.target.value);
        }}
        sx={{
          flex: 1,

          "& input": {
            fontSize: ".875rem",
            p: "4px 10px",
          },
        }}
      />

      <IconButton
        type="reset"
        onClick={() => {
          setMax("");
        }}
        size="small"
        sx={{
          "& svg": {
            widht: "20px",
            height: "20px",
          },
        }}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}

function WalletSell(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { tokenAddress } = useParams();

  const { info, wallet, balance, tokenPrice, percentAmount, sellGasUsed } =
    props;

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [fetching, setFetching] = useState();
  const sell = async () => {
    // Check amount
    if (!balance) {
      enqueueSnackbar(`You don't have any token to sell`, {
        variant: "warning",
      });
      return false;
    }
    if (!percentAmount) {
      enqueueSnackbar(`Can not sell zero token`, { variant: "warning" });
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      // Amount
      let amount;
      if (percentAmount === 100) {
        amount = 0;
      } else {
        amount = ((balance * percentAmount) / 100) * 10 ** info.tokenDecimals;
      }

      const PaiBotWallets = await PaiBotWalletsTrade(
        connectedWalletAddress,
        connectedWalletSignedData,
        "sell",
        wallet,
        tokenAddress,
        amount,
        sellGasUsed * 1.3
      );

      if (PaiBotWallets.error && PaiBotWallets.error.message) {
        enqueueSnackbar(PaiBotWallets.error.message, { variant: "warning" });
      } else if (PaiBotWallets.success && PaiBotWallets.success.message) {
        enqueueSnackbar(PaiBotWallets.success.message);
      }

      // Turn off fetching
      setFetching(false);
    }
  };

  const isDisabled = () => {
    if (fetching) {
      return true;
    }

    if (!balance) {
      return true;
    }

    if (balance * tokenPrice < 0.0001) {
      return true;
    }

    return false;
  };

  return (
    <Button
      variant="contained"
      size="small"
      color="error"
      disabled={isDisabled()}
      onClick={sell}
    >
      Sell {percentAmount}%
    </Button>
  );
}

export default function WalletSwap(props) {
  const { info, wallet, bnbBalance, balance, tokenPrice, tokenAnalyse } = props;

  const buyGasUsed = tokenAnalyse.buyGasUsed,
    sellGasUsed = tokenAnalyse.sellGasUsed;

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiButtonBase-root": {
            textTransform: "none",
            fontWeight: "normal",
            width: "100%",
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.01}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.02}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.05}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.1}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.2}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <WalletBuy
                wallet={wallet}
                bnbBalance={bnbBalance}
                amount={0.5}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <WalletBuyX
                wallet={wallet}
                bnbBalance={bnbBalance}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WalletBuyRandom
                wallet={wallet}
                bnbBalance={bnbBalance}
                buyGasUsed={buyGasUsed}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <WalletSell
                info={info}
                wallet={wallet}
                balance={balance}
                tokenPrice={tokenPrice}
                percentAmount={25}
                sellGasUsed={sellGasUsed}
              />
            </Grid>
            <Grid item xs={3}>
              <WalletSell
                info={info}
                wallet={wallet}
                balance={balance}
                tokenPrice={tokenPrice}
                percentAmount={50}
                sellGasUsed={sellGasUsed}
              />
            </Grid>
            <Grid item xs={3}>
              <WalletSell
                info={info}
                wallet={wallet}
                balance={balance}
                tokenPrice={tokenPrice}
                percentAmount={75}
                sellGasUsed={sellGasUsed}
              />
            </Grid>
            <Grid item xs={3}>
              <WalletSell
                info={info}
                wallet={wallet}
                balance={balance}
                tokenPrice={tokenPrice}
                percentAmount={100}
                sellGasUsed={sellGasUsed}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
