// Packages
import { useEffect, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Components
import ProfitItem from 'components/pages/home/ProfitItem';

// Services
import {
  PAIProfitsGet,
} from 'services/PAIProfits';

export default function LatestProfits() {
  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [latestProfits, setLatestProfits] = useState([]);

  const fetchLatestProfits = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "timestamp DESC",
          "limit": 10,
        },
        PAIProfits = await PAIProfitsGet(opt);

      if (PAIProfits.error) {
        // Catch error and set error message
        setError(PAIProfits.error);
      } else {
        setLatestProfits(PAIProfits);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchLatestProfits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Box sx={{
        mt: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body2" sx={{
          fontWeight: 'bold',
          lineHeight: '24px',
        }}>
          Latest Profits
        </Typography>
      </CardContent>

      <Divider />

      {fetching &&
        <Box sx={{
          py: 6,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Box>
      }

      <CardContent sx={{
        '& hr': {
          my: 2,
        },
      }}>
        {!fetching &&
          <Box>
            {/* Empty */}
            {latestProfits.length === 0 &&
              <Box sx={{
                py: 6,
                textAlign: 'center',
              }}>
                Not found any profits.
              </Box>
            }

            {latestProfits.length > 0 &&
              latestProfits.map((latestProfit) => (
                <ProfitItem key={latestProfit.id} isProAccount={true} profits={latestProfits} profit={latestProfit} />
              ))
            }
          </Box>
        }

      </CardContent>

      {/* WIP
      <Divider />

      <CardActions sx={{
        p: 2,
      }}>
        <Button variant="contained" size="small" sx={{
          width: '100%',
        }}>View all profits</Button>
      </CardActions>
      */}
    </Card>
  );
}
