// Packages
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import * as React from 'react';

// Library
import {
  formatToken,
  getShortAddress,
} from 'libraries/Common';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// Icons
import TokenIcon from '@mui/icons-material/Token';
import WalletIcon from '@mui/icons-material/Wallet';

// Services
import {
  SafuCheckGoPlus,
} from 'services/SafuCheck';



export default function ProfitItem(props) {
  const {
    isProAccount,
    profit,
    profits,
  } = props;

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [safu, setSafu] = useState();

  const fetchSafuCheck = async (tokenAddress) => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        safuCheck = await SafuCheckGoPlus(tokenAddress);

      if (safuCheck.error) {
        // Catch error and set error message
        setError(safuCheck.error);
      } else {
        setSafu(safuCheck);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    if (profit.tokenAddress) {
      fetchSafuCheck(profit.tokenAddress);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHoneypot = () => {
    if (
      safu
      && safu.result
      && safu.result[profit.tokenAddress.toLowerCase()]
      && safu.result[profit.tokenAddress.toLowerCase()].is_honeypot
      && safu.result[profit.tokenAddress.toLowerCase()].is_honeypot === "1") {
      return true;
    }

    return false;
  }



  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
    <Box>
      <Grid container spacing={2} sx={{
        minHeight: {
          lg: '117px',
        },
      }}>
        <Grid item xs={12} sm={6} md={12} lg={6} sx={{
          minHeight: {
            md: '117px',
          },
        }}>
          <Typography variant="body2" className="numnber" sx={{
            lineHeight: '24px',
            display: 'flex',
          }}>
            <WalletIcon sx={{
              fontSize: 16,
              mt: 0.5,
            }} />
            {!isProAccount &&
              <React.Fragment>
                <Link title={getShortAddress(profit.wallet)} onClick={handleOpen} sx={{
                  cursor: "pointer",
                  paddingLeft: 1,
                  textDecoration: "none",
                }}>{getShortAddress(profit.wallet)}</Link>
                <Dialog
                  open={open}
                  onClose={handleClose}
                >
                  <DialogTitle>
                    Pro function
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions. You can buy PAI now on <Link sx={{
                        fontWeight: 'bold',
                      }} href="https://pancakeswap.finance/swap?outputCurrency=0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32" target="_blank">PancakeSwap</Link>.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            }
            {isProAccount &&
              <Link title={profit.wallet} component={RouterLink} to={`/token/${profit.tokenAddress}/transactions/${profit.wallet}`} sx={{
                paddingLeft: 1,
                textDecoration: 'none',
              }}>{getShortAddress(profit.wallet)}</Link>
            }
          </Typography>
          <Typography variant="body2" className="numnber" sx={{
            lineHeight: '24px',
            display: 'flex',
          }}>
            <TokenIcon sx={{
              fontSize: 16,
              mt: 0.5,
            }} />
            {!profit.tokenAddress &&
              <Typography variant="span" className="numnber" sx={{
                paddingLeft: 1,
                fontStyle: 'italic',
              }}>
                Undefined
              </Typography>
            }
            {profit.tokenAddress &&
              <Link title={`${profit.tokenName} (${profit.tokenSymbol})`} component={RouterLink} to={`/token/${profit.tokenAddress}`} sx={{
                paddingLeft: 1,
                textDecoration: 'none',
              }}>{profit.tokenName} ({profit.tokenSymbol})</Link>
            }
          </Typography>

          <Typography variant="body2" className="numnber">
            💰 <span className="grey">Estimated total profit: {` `}</span>
            <Typography variant="span" className="numnber" sx={{
              color: 'success.main',
              fontWeight: 'bold',
            }}>
              {formatToken(profit.estimatedTotalProfit * 100, 2, 2)}%
            </Typography>
          </Typography>

          <Typography variant="body2" className="numnber" sx={{
            ml: '22px',
          }}>
            <span className="grey">
              {fetching && 'Checking SAFU...'}
              {!fetching && error && error.message}
            </span>
          </Typography>

          {!fetching && !error && isHoneypot() &&
            <Chip label="POTENTIAL HONEYPOT" color="error" size="small" sx={{
              mt: 1,
            }} />
          }
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Typography variant="body2" className="numnber">
            🟢 <span className="grey">Total bought:</span> {profit.totalBoughtTxn} Txn - <strong>{formatToken(profit.totalBoughtAmount, 3, 3)} BNB</strong><br />
            🔴 <span className="grey">Total sold:</span> {profit.totalSoldTxn} Txn - <strong>{formatToken(profit.totalSoldAmount, 3, 3)} BNB</strong><br />
            🟡 <span className="grey">Total fee:</span> {profit.totalFeeTxn} Txn - <strong>{formatToken(profit.totalFeeAmount, 3, 3)} BNB</strong><br />
            🔵 <span className="grey">Real profit:</span> <Chip component="span" label={`${formatToken(profit.realProfit, 3, 3)} BNB`} color="success" size="small" /><br />
          </Typography>
        </Grid>
      </Grid>

      {profit.id !== profits[profits.length - 1].id &&
        <Divider />
      }
    </Box>
  );
}
