const PRO_WALLETS = [
  "0xa7b34ff392cD1D93Eac427a549d10B3C56482120",
  "0xec9F6eE4fa743D9aA6A58a061aD28181a6c3D5CD",
  "0xDe48b0aa0675b94Ef1A6F9B011004fFBde466085",
  "0xD152fE91bF703342dc2510d7ed68b32b073C0Df8",
  "0x889f7564f4Eef48E2719e0BF661ebCc1bA789b15",

  "0x26fED3b7752AA702F39523DeB0E8b3Ef5242B042",
];

export function isProAccount(
  connectedWalletIsConnected,
  connectedWalletAddress,
  paiBalance
) {
  if (PRO_WALLETS.includes(connectedWalletAddress)) {
    return true;
  }

  if (
    connectedWalletIsConnected &&
    connectedWalletAddress &&
    paiBalance >= 100
  ) {
    return true;
  }

  return false;
}
