export async function TelegramGroupMembersGet(tgId) {
  const
    url = `${process.env.REACT_APP_API_URL}telegrams/group-members/${tgId}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
