// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Components
import Socials from 'components/Socials';

export default function Footer() {
  return (
    <Box>
      <Box sx={{
        py: 0.6,
        backgroundColor: "grey",
      }}>
        <Container>
          <Typography variant="body2" sx={{
            color: "white",
            textAlign: "center",
            fontSize: 12,
          }}>
            <strong>Disclamer:</strong> Profit AI started tracking tokens a month ago. We are not responsible for the lack of data compared to the blockchain. We already plan to track all data from the beginning in the near future.
          </Typography>
        </Container>
      </Box>

      <Box component="footer" sx={{
        py: 2,
        backgroundColor: "#000616",
      }}>
        <Container>
          <Typography variant="body2" sx={{
            color: "white",
            textAlign: "center",
            fontSize: 13,
          }}>
            Copyright © {new Date().getFullYear()} <Box component="span" sx={{ fontWeight: "bold", }}>Profit AI</Box>. All Rights Reserved
          </Typography>

          <Box sx={{
            mt: 1,
            textAlign: 'center',
          }}>
            <Socials
              buttonBackground="white"
              buttonBackgroundHover="#ffffffb3"
              buttonColor="primary.main"
              buttonColorHover="secondary.main"
            ></Socials>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
