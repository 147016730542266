// Packages
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from 'react-redux';

// Library
import {
  formatDate,
  formatToken,
  getShortAddress,
} from 'libraries/Common';

// Material UI
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Store
import {
  selectBnbPrice,
} from 'app/bnbSlice';



export default function TransactionItem(props) {
  const bnbPrice = useSelector(selectBnbPrice);

  const {
    tokenAddress,
    transaction,
    showWallet,
    showCurrentValue,
    info,
    tokenPrice,
  } = props;

  let
    label,
    chipColor,
    color,
    bnbAmount,
    usdAmount,
    currentUsdAmount = '',
    _tokenAmount,
    tokenAmount;

  if (transaction.method === "buy") {
    label = "Buy";
    chipColor = "success";
    color = "success.main";
    _tokenAmount = parseFloat(transaction.gotFormatted.replace(/,/g, ''));
    tokenAmount = `${formatToken(_tokenAmount, 3, 3)} ${info.symbol}`;
    bnbAmount = `${formatToken(transaction.spentFormatted, 3, 3)} BNB`;
    usdAmount = `$${formatToken(transaction.spentFormatted * bnbPrice, 2, 2)}`;
    currentUsdAmount = `$${formatToken(_tokenAmount * tokenPrice * bnbPrice, 2, 2)}`;
  } else if (transaction.method === "sell") {
    label = "Sell";
    chipColor = "error";
    color = "error.main";
    _tokenAmount = parseFloat(transaction.spentFormatted.replace(/,/g, ''));
    tokenAmount = `${formatToken(_tokenAmount, 3, 3)} ${info.symbol}`;
    bnbAmount = `${formatToken(transaction.gotFormatted, 3, 3)} BNB`;
    usdAmount = `$${formatToken(transaction.gotFormatted * bnbPrice, 2, 2)}`;
    currentUsdAmount = `$${formatToken(_tokenAmount * tokenPrice * bnbPrice, 2, 2)}`;
  } else if (transaction.method === "approve") {
    label = "Approve";
    tokenAmount = "MAX";
    bnbAmount = `${formatToken(transaction.feeFormatted, 6, 6)} BNB`;
    usdAmount = `$${formatToken(transaction.feeFormatted * bnbPrice, 2, 2)}`;
    currentUsdAmount = usdAmount;
  }

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Chip label={label} color={chipColor} size="small" />
      </TableCell>
      <TableCell align="right">
        <Typography color={color}>
          {tokenAmount}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color={color}>
          {bnbAmount}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color={color}>
          {usdAmount}
        </Typography>
      </TableCell>
      {showCurrentValue &&
        <TableCell align="right">
          <Typography color={color}>
            {currentUsdAmount}
          </Typography>
        </TableCell>
      }
      {showWallet &&
        <TableCell>
          <Typography color={color}>
            <Link title={transaction.wallet} component={RouterLink} to={`/token/${tokenAddress}/transactions/${transaction.wallet}`}>{getShortAddress(transaction.wallet)}</Link>
          </Typography>
        </TableCell>
      }
      <TableCell>
        <Typography color={color}>
          {formatDate(transaction.timestamp)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color={color}>
          <Link title="View on Bscscan" href={`https://bscscan.com/tx/${transaction.hash}`} target="_blank">
            {transaction.hash.substring(transaction.hash.length - 6)}
          </Link>
        </Typography>
      </TableCell>
    </TableRow>
  );
}
