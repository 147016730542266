// Packages
import { useEffect, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Services
import {
  ProfitAiBotTokensCount,
} from 'services/ProfitAiBotToken';

// Library
import {
  formatToken,
} from 'libraries/Common';



export default function BotStatistics() {
  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [botStatistics, setBotStatistics] = useState([]);

  const fetchBotStatistics = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        botStatistics = await ProfitAiBotTokensCount();

      if (botStatistics.error) {
        // Catch error and set error message
        setError(botStatistics.error);
      } else {
        setBotStatistics(botStatistics);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchBotStatistics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  let
    groupUsage;
  if (error && error.message) {
    groupUsage = error.message;
  } else if (botStatistics && botStatistics.count) {
    groupUsage = botStatistics.count;
  }



  return (
    <Box>
      <Box sx={{
        display: 'flex',
        pt: 2,
      }}>
        <Box sx={{
          flex: 1,
        }}>
          <Typography variant="body2" sx={{
            lineHeight: '30px',
            fontSize: 21,
          }}>
            Bot Statistics
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{
        mt: 0,

        '& .xs-no-pt': {
          pt: {
            xs: '0 !important',
            sm: '16px !important',
          },
        },
      }}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body2" sx={{
                fontWeight: 'bold',
                display: 'inline-block',
              }}>
                Post Number
              </Typography>
            </CardContent>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Bot Buy Posts:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    NaN
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Bot Profit Posts:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    NaN
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body2" sx={{
                fontWeight: 'bold',
                display: 'inline-block',
              }}>
                Group Number
              </Typography>
            </CardContent>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Group Usage:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" className="text-truncate" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    {formatToken(groupUsage, 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Total Tokens:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" className="text-truncate" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    {formatToken(groupUsage, 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
