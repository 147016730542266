// Packages
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Components
import Chart from 'components/pages/token/Chart';
import Info from 'components/pages/token/Info';
import Tabs from 'components/pages/token/Tabs';
import TradingInfo from 'components/pages/token/TradingInfo';
import WalletTabs from 'components/pages/token/WalletTabs';
import WalletTrackerItem from 'components/pages/token/WalletTrackerItem';
import YourWalletInfo from 'components/pages/token/YourWalletInfo';

// Library
import {
  calcTokenPrice,
  getTokenAnalyse,
  getTokenInfo,
} from 'libraries/Web3';
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectPaiPrice,
  selectPaiBalance,
} from 'app/paiSlice';
import {
  selectAddress,
  selectIsConnected,
} from 'app/connectedWalletSlice';

// Services
import {
  SafuCheckGoPlus,
} from 'services/SafuCheck';



function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}



// custom hook for getting previous value
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}



function RenderTitle(props) {
  const {
    title,
  } = props;

  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        py: 2,
        mt: 2,
      }}>
        <Box sx={{
          flex: 1,
        }}>
          <Typography variant="body2" sx={{
            lineHeight: '30px',
            fontSize: 21,

            '& span': {
              color: 'grey',
              fontSize: '17px',
              ml: 1,
            },
          }}>
            {title}
          </Typography>
        </Box>
      </Box>

      <Divider />
    </React.Fragment>
  );
}



export default function Token() {
  const routeMatch = useRouteMatch([
    '/token/:tokenAddress',
    '/token/:tokenAddress/latest-profits',
    '/token/:tokenAddress/transactions',
    '/token/:tokenAddress/transactions/:wallet',
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const {
    tokenAddress,
    wallet,
  } = useParams();

  const prevTokenAddress = usePrevious(tokenAddress);

  const paiPrice = useSelector(selectPaiPrice);

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const [error, setError] = useState();

  const [fetching, setFetching] = useState();
  const [info, setInfo] = useState(false);

  // declare the data fetching function
  const fetchData = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      // Reset error
      setError();

      // Reset info & tokenAnalyse when changing the token address
      if (prevTokenAddress !== tokenAddress) {
        setInfo(false);
        setTokenAnalyse(false);
      }

      try {
        // Get token info
        const tokenInfo = await getTokenInfo(tokenAddress);
        if (tokenInfo) {
          setInfo(tokenInfo);
        } else if (!info) {
          setError(true);
        }
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetching(false);
    }
  }



  const [fetchingTokenAnalyse, setFetchingTokenAnalyse] = useState();
  const [tokenAnalyse, setTokenAnalyse] = useState(false);

  const fetchTokenAnalyse = async (tokenAddress) => {
    // Check PRO
    if (tokenAnalyse !== false && !_isProAccount()) {
      return false;
    }

    // Only fetch once a time
    if (!fetchingTokenAnalyse) {
      // Turn on fetching
      setFetchingTokenAnalyse(true);

      // Reset tokenAnalyse when changing the token address
      if (prevTokenAddress !== tokenAddress) {
        setTokenAnalyse(false);
      }

      try {
        const _tokenAnalyse = await getTokenAnalyse(tokenAddress);
        setTokenAnalyse(_tokenAnalyse);
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetchingTokenAnalyse(false);
    }
  }



  const [fetchingTokenPrice, setFetchingTokenPrice] = useState();
  const [tokenPrice, setTokenPrice] = useState(false);

  const fetchTokenPrice = async () => {
    if (
      tokenAddress === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" // BNB
      || tokenAddress === "0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32" // PAI
    ) {
      return false;
    }

    // Only fetch once a time
    if (!fetchingTokenPrice) {
      // Turn on fetching
      setFetchingTokenPrice(true);

      // Reset price when changing the token address
      if (prevTokenAddress !== tokenAddress) {
        setTokenPrice(false);
      }

      try {
        // Get tokenPrice
        const _tokenPrice = await calcTokenPrice(tokenAddress);
        setTokenPrice(_tokenPrice);
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetchingTokenPrice(false);
    }
  }



  const [fetchingSafu, setFetchingSafu] = useState();
  const [safu, setSafu] = useState(false);

  const fetchSafuCheck = async (tokenAddress) => {
    // Check PRO
    if (safu !== false && !_isProAccount()) {
      return false;
    }

    // Only fetch once a time
    if (!fetchingSafu) {
      // Turn on fetching
      setFetchingSafu(true);

      // Reset safu when changing the token address
      if (prevTokenAddress !== tokenAddress) {
        setSafu(false);
      }

      try {
        const
          safuCheck = await SafuCheckGoPlus(tokenAddress);

        setSafu(safuCheck);
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetchingSafu(false);
    }
  }



  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(Math.random());
    }, 1000 * 10);

    return (() => {
      clearInterval(intervalId);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
    fetchTokenPrice();
    fetchSafuCheck(tokenAddress);
    fetchTokenAnalyse(tokenAddress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, tokenAddress]);

  let
    propsTokenPrice = tokenPrice;

  // BNB
  if (tokenAddress === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
    propsTokenPrice = 1;
  }

  // PAI
  if (tokenAddress === "0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32") {
    propsTokenPrice = paiPrice;
  }

  if (error) {
    return (
      <Box sx={{
        my: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        Token not found
      </Box>
    )
  }

  const holderCount = () => {
    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].holder_count) {
      return safu.result[tokenAddress.toLowerCase()].holder_count;
    }

    return 'Undefined';
  }

  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,
    }}>
      <Container>
        <Box>
          <Info
            info={info}
            tokenPrice={propsTokenPrice}
            safu={safu}
            holderCount={holderCount()}
          />



          <RenderTitle title="Trading Info" />

          {tokenAnalyse &&
            <TradingInfo
              info={info}
              tokenPrice={propsTokenPrice}
              tokenAnalyse={tokenAnalyse}
            />
          }

          <Chart />



          <RenderTitle title="Multiple Wallet Tracker" />

          {tokenAnalyse &&
            <YourWalletInfo
              info={info}
              tokenPrice={propsTokenPrice}
              tokenAnalyse={tokenAnalyse}
            />
          }



          <RenderTitle title="Detail" />

          {currentTab !== "/token/:tokenAddress/transactions/:wallet" &&
            <Tabs
              info={info}
              tokenPrice={propsTokenPrice}
            />
          }

          {currentTab === "/token/:tokenAddress/transactions/:wallet" &&
            <Box>
              {tokenAnalyse &&
                <WalletTrackerItem
                  info={info}
                  tokenPrice={propsTokenPrice}
                  tokenAnalyse={tokenAnalyse}
                  wallet={wallet}
                  name="FILTERED BY TOKEN HOLDER"
                  filter={true}
                />
              }

              <WalletTabs
                currentTab={currentTab}
                info={info}
                tokenPrice={propsTokenPrice}
              />
            </Box>
          }
        </Box>
      </Container>
    </Box>
  );
}
