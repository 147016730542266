// Packages
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useSignMessage } from 'wagmi';
import { useSnackbar } from 'notistack';
import { Web3Button } from "@web3modal/react";
import * as React from 'react';

// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// React Hook Form Mui
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';

// Icons
import DiamondIcon from '@mui/icons-material/Diamond';
import SearchIcon from '@mui/icons-material/Search';

// Library
import {
  formatToken,
} from 'libraries/Common';
import {
  isBscTokenAddress,
} from 'libraries/Web3';

// Components
import MenuDesktop from 'components/header/MenuDesktop';
import MenuMobile from 'components/header/MenuMobile';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
  setSignedData,
} from 'app/connectedWalletSlice';
import {
  selectBnbPrice,
} from 'app/bnbSlice';
import {
  selectPaiBalance,
  selectPaiPrice,
} from 'app/paiSlice';

// Images
import logo from 'images/logo.png';
import logo_mb from 'images/new_logo_light_transparent.png';

// Library
import {
  isProAccount,
} from 'libraries/Pro';



export default function Header() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);



  const bnbPrice = useSelector(selectBnbPrice);
  const paiBalance = useSelector(selectPaiBalance);
  const paiPrice = useSelector(selectPaiPrice);



  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const onSubmit = async data => {
    if (data && data.q) {
      const address = await isBscTokenAddress(data.q);
      if (address) {
        navigate(`/token/${address}`);
      } else {
        enqueueSnackbar('Token not found', { variant: 'warning' });
      }
    }
  }



  const { isLoading, signMessage } = useSignMessage({
    message: 'Verify Profit AI (PAI)',
    onSuccess(data) {
      dispatch(setSignedData(data));
    },
    onError(error) {
      enqueueSnackbar(error.toString(), { variant: 'warning' });
      dispatch(setSignedData(null));
    },
  });



  const connectedWalletSignedData = useSelector(selectSignedData);



  return (
    <AppBar position="static" sx={{
      background: 'transparent',
      boxShadow: '0 1px 10px rgb(151 164 175 / 10%)',
      color: 'text.primary',
      position: 'relative',
      zIndex: 1,
    }}>
      <Container sx={{
        padding: {
          xs: 0,
        },
      }}>
        <Toolbar>
          <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>



            {/* Logo */}
            <Box sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'flex-end',
            }}>
              {/* Link to Home */}
              <Link component={RouterLink} to="/">
                <CardMedia
                  component="img"
                  src={logo_mb}
                  alt="Logo Profit AI Mobile"
                  sx={{
                    width: 40,
                    height: 40,
                    display: {
                      sm: 'none',
                    },
                  }}
                />
                <CardMedia
                  component="img"
                  src={logo}
                  alt="Logo Profit AI"
                  sx={{
                    width: 201,
                    height: 30,
                    display: {
                      xs: 'none',
                      sm: 'block',
                    },
                  }}
                />
              </Link>
              {/* Link to Home */}

              {_isProAccount() &&
                <Typography variant="body2" sx={{
                  ml: 1,
                  mb: '-4px',
                  color: 'grey',
                }}>
                  PRO
                </Typography>
              }
            </Box>
            {/* Logo */}



            {/* Search box */}
            <Paper variant="outlined" sx={{
              position: {
                xs: 'absolute',
                md: 'static',
              },
              top: {
                xs: '56px',
                sm: '64px',
              },
              width: {
                xs: 'calc(100% - 32px)',
                sm: 'calc(100% - 48px)',
                md: 'auto',
              },
            }}>
              <FormContainer onSuccess={onSubmit}>
                <Box sx={{
                  display: 'flex',
                }}>
                  <TextFieldElement
                    sx={{
                      flex: 1,
                      "& fieldset": { border: 'none' },
                      "& label.Mui-focused, & label.MuiFormLabel-filled": {
                        display: 'none',
                      },
                      minWidth: {
                        xs: 'auto',
                        md: '360px',
                        lg: '400px',
                      },
                      "& input": {
                        fontSize: ".875rem",
                        height: '27px',
                      },
                    }}
                    label={'Search by Token'}
                    name={'q'}
                    size="small"
                  />
                  <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Box>
              </FormContainer>
            </Paper>
            {/* Search box */}



            <MenuMobile />
            <Divider orientation="vertical" sx={{
              height: 20,
              my: 1,
              mx: 2,
            }} />



            {/* Web3 button */}
            <Web3Button />
            {/* Web3 button */}



            {/* Sign button */}
            {connectedWalletIsConnected && connectedWalletAddress &&
              <Button disabled={isLoading} onClick={() => signMessage()}
                sx={{
                  ml: 1,
                }}
              >
                {!connectedWalletSignedData && 'SIGN'}
                {connectedWalletSignedData && 'SIGNED'}
              </Button>
            }
            {/* Sign button */}



          </Box>
        </Toolbar>



        <Toolbar variant="dense" sx={{
          mt: {
            xs: 5.5,
            md: 0,
          },
        }}>
          <Box sx={{
            width: '100%',
            display: {
              xs: 'block',
              sm: 'flex',
            },
            alignItems: 'center',
          }}>



            <Box sx={{
              display: 'flex',
              flexGrow: 1,
              mt: {
                xs: 2,
                sm: 1,
              },
              mb: 1,
            }}>
              <Typography variant="body2" sx={{
                fontSize: 12,
              }}>

                {/* BNB & PAI Price */}
                {bnbPrice !== false &&
                  <React.Fragment>
                    <Link title="BNB" component={RouterLink} to={`/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c`} sx={{
                      textDecoration: 'none',
                    }}>
                      BNB
                    </Link>
                    : ${formatToken(bnbPrice, 3, 3)}

                    <Typography variant="span" sx={{
                      mx: 1,
                    }}>|</Typography>
                  </React.Fragment>
                }

                <Typography variant="span" sx={{
                  '& span': {
                    color: "#77838f",
                    fontSize: 11,
                  },
                }}>
                  <Link title="PAI" component={RouterLink} to={`/token/0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32`} sx={{
                    textDecoration: 'none',
                  }}>
                    PAI
                  </Link>
                  : ${formatToken(paiPrice * bnbPrice, 6, 6)} <span>({formatToken(paiPrice, 6, 6)} BNB)</span>
                </Typography>

                <Tooltip
                  title="Real-time"
                  placement="top"
                  arrow
                >
                  <Box component="span" className="living"></Box>
                </Tooltip>
                {/* BNB & PAI Price */}

                <br />

                {connectedWalletIsConnected && connectedWalletAddress &&
                  <React.Fragment>
                    Your <strong>PAI</strong> balance:

                    <Typography variant="span" sx={{
                      pl: 0.5,
                    }}>
                      {paiBalance === 0 &&
                        <React.Fragment>
                          0 <strong>PAI</strong>
                        </React.Fragment>
                      }
                      {paiBalance !== false && paiBalance !== 0 &&
                        <React.Fragment>
                          {formatToken(paiBalance, 3, 3)} <strong>PAI</strong>
                        </React.Fragment>
                      }
                    </Typography>

                    {` - `}

                    <Typography variant="span" sx={{
                      '& span': {
                        color: "#77838f",
                        fontSize: 11,
                      },
                    }}>
                      {paiBalance === 0 && '$0.00'}
                      {paiBalance !== false && paiBalance !== 0 &&
                        <React.Fragment>
                          ${formatToken(paiBalance * paiPrice * bnbPrice, 2, 2)} <span>({formatToken(paiBalance * paiPrice, 6, 6)} BNB)</span>
                        </React.Fragment>
                      }
                    </Typography>

                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  </React.Fragment>
                }
              </Typography>
            </Box>



            {_isProAccount() &&
              <Chip icon={<DiamondIcon />} label="Lifetime access" color="primary" size="small" sx={{
                mr: {
                  xs: 0,
                  md: 2,
                },
                mb: {
                  xs: 2,
                  sm: 0,
                },
              }} />
            }



            <MenuDesktop />



          </Box>
        </Toolbar>



      </Container>
    </AppBar>
  );
}
