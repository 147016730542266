// Packages
import {
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import * as React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

// Material UI
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

// Store
import {
  selectAddress,
} from 'app/connectedWalletSlice';

// Library
import {
  isMasterAccount,
} from 'libraries/Master';

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}



export default function MenuDesktop() {
  const navigate = useNavigate();



  const connectedWalletAddress = useSelector(selectAddress);



  const routeMatch = useRouteMatch([
    '/functions/wallet-tracking',
    '/functions/telegram-connection',
    '/functions/bot-wallet',
  ]);
  const currentPath = routeMatch?.pattern?.path;



  const _isMasterAccount = () => {
    return isMasterAccount(connectedWalletAddress);
  }



  return (
    <Stack direction="row" spacing={2} sx={{
      display: {
        xs: 'none',
        md: 'block',
      },

      '& button': {
        textTransform: 'none',
        fontWeight: 'normal',
        color: 'text.primary',
      },
    }}>

      <Button onClick={() => {
        navigate(`/`);
      }}
      >
        Home
      </Button>



      <PopupState variant="popover" popupId="main-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              {...bindTrigger(popupState)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Governances
            </Button>
            <Menu {...bindMenu(popupState)} sx={{
              '& li': {
                fontSize: 14,
              },
            }}>
              <MenuItem onClick={() => {
                navigate(`/governances/meme-contest`);
                popupState.close();
              }}>Meme contest</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>



      <PopupState variant="popover" popupId="main-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              {...bindTrigger(popupState)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Functions
            </Button>
            <Menu {...bindMenu(popupState)} sx={{
              '& li': {
                fontSize: 14,
              },
            }}>
              <MenuItem
                selected={currentPath === '/functions/wallet-tracking'}
                onClick={() => {
                  navigate(`/functions/wallet-tracking`);
                  popupState.close();
                }}
              >Wallet Tracking</MenuItem>
              <MenuItem
                selected={currentPath === '/functions/telegram-connection'}
                onClick={() => {
                  navigate(`/functions/telegram-connection`);
                  popupState.close();
                }}
              >Telegram Connection</MenuItem>
              <MenuItem
                selected={currentPath === '/functions/bot-wallet'}
                onClick={() => {
                  navigate(`/functions/bot-wallet`);
                  popupState.close();
                }}
              >Bot Wallet</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>



      {_isMasterAccount() &&
        <PopupState variant="popover" popupId="main-menu">
          {(popupState) => (
            <React.Fragment>
              <Button
                {...bindTrigger(popupState)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Secret functions
              </Button>
              <Menu {...bindMenu(popupState)} sx={{
                '& li': {
                  fontSize: 14,
                },
              }}>
                <MenuItem onClick={() => {
                  navigate(`/secret-functions/sniper`);
                  popupState.close();
                }}>Sniper</MenuItem>
                <MenuItem onClick={() => {
                  navigate(`/secret-functions/crazy-dev`);
                  popupState.close();
                }}>Crazy Dev</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      }



    </Stack>
  );
}
