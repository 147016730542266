// Packages
import {
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSnackbar } from 'notistack';

// Material UI
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

// Library
import {
  isBscTokenAddress,
} from 'libraries/Web3';

export default function WalletFilter(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentTab } = props;

  const { tokenAddress, wallet } = useParams();

  const [searchWallet, setSearchWallet] = useState(wallet || '');

  useEffect(() => {
    setSearchWallet(wallet);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const onSubmit = async () => {
    if (searchWallet) {
      const _searchWallet = await isBscTokenAddress(searchWallet);
      if (_searchWallet) {
        navigate(`/token/${tokenAddress}/transactions/${_searchWallet}`);
      } else {
        enqueueSnackbar('Please enter a wallet', { variant: 'warning' });
      }
    }
  }

  return (
    <Paper
      variant="outlined"
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      sx={{
        display: 'flex',
        m: {
          xs: 2,
          md: 0,
        },
        position: {
          md: 'absolute',
        },
        top: 6,
        right: 16,
      }}
    >
      <InputBase
        size="small"
        placeholder="Filter by a wallet"
        value={searchWallet}
        onChange={(e) => { setSearchWallet(e.target.value) }}
        sx={{
          flex: 1,

          "& input": {
            fontSize: ".875rem",
            width: {
              md: currentTab === "/token/:tokenAddress/transactions/:wallet" ? 340 : 240,
              lg: 340,
            },
            height: {
              md: 20,
            },
            p: {
              xs: '8.5px 14px',
              md: '4px 10px',
            },
          },
        }}
      />

      <IconButton
        type="reset"
        onClick={() => {
          navigate(`/token/${tokenAddress}/transactions`);
        }}
        size="small"
      >
        <ClearIcon />
      </IconButton>

      <Divider orientation="vertical" sx={{
        height: 24,
        margin: {
          xs: '8px 4px',
          md: '4px',
        },
      }} />
      <IconButton
        type="submit"
        size="small"
        sx={{
          my: {
            xs: 0.2,
            md: 0,
          },
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
