// Packages
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Icons
import TokenIcon from '@mui/icons-material/Token';

// Library
import {
  formatToken,
  formatTokenDynamic,
} from 'libraries/Common';
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectBnbPrice,
} from 'app/bnbSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';
import {
  selectAddress,
  selectIsConnected,
} from 'app/connectedWalletSlice';

// Images
import bscscan from 'images/bscscan_40x40.png';
import DexScreenerLogo from 'images/DexScreenerLogo.svg';
import pancakeswapCakeLogo from 'images/pancakeswap-cake-logo.svg';
import poocoinLogo from 'images/poocoin-logo.svg';

// Components
import Audit from 'components/pages/token/Audit';

// Const
const ICON_SIZE = "30px";



export default function Info(props) {
  const {
    tokenAddress,
  } = useParams();

  const bnbPrice = useSelector(selectBnbPrice);



  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const {
    holderCount,
    info,
    safu,
    tokenPrice,
  } = props;

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        py: 2,
      }}>
        <Box sx={{
          width: ICON_SIZE,
          height: ICON_SIZE,

          '& .MuiSvgIcon-root': {
            width: ICON_SIZE,
            height: ICON_SIZE,
          },
        }}>
          <TokenIcon />
        </Box>
        <Box sx={{
          textAlign: 'left',
          ml: 1,
          flex: 1,
        }}>
          <Typography variant="body2" sx={{
            lineHeight: '30px',
            fontSize: 21,

            '& span': {
              color: 'grey',
              fontSize: '17px',
              ml: 1,
            },
          }}>
            Token
            <span>{info.name}</span>
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Grid container spacing={2} sx={{
        mt: 0,

        '& .xs-no-pt': {
          pt: {
            xs: '0 !important',
            sm: '16px !important',
          },
        },
      }}>
        <Grid item xs={12} md={6}>

          <Card variant="outlined">
            <CardContent>
              <Typography variant="body2" sx={{
                fontWeight: 'bold',
                display: 'inline-block',
              }}>
                Overview
              </Typography>
              <Chip label="BEP-20" size="small" sx={{
                ml: 1,
              }} />
            </CardContent>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body2" sx={{
                    color: "#77838f",
                    fontSize: 11,
                  }}>
                    PRICE
                  </Typography>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,

                    '& span.bnb': {
                      color: "#77838f",
                      fontSize: 11,
                      ml: 0.5,
                    },
                  }}>
                    <Box component="span">
                      ${formatTokenDynamic(tokenPrice * bnbPrice)}
                    </Box>
                    <Box component="span" className="bnb">
                      ({formatTokenDynamic(tokenPrice)} BNB)
                    </Box>
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{
                    color: "#77838f",
                    fontSize: 11,
                  }}>
                    <Tooltip
                      title="Total Supply * Price"
                      placement="top"
                      arrow
                    >
                      <Box component="span" sx={{
                        borderBottomWidth: 1,
                        borderBottomColor: 'var(--chakra-colors-gray-600)',
                        borderBottomStyle: 'dashed',
                      }}>
                        MARKET CAP
                      </Box>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    <Box component="span">
                      ${formatToken(info.totalSupply * tokenPrice * bnbPrice, 0, 0)}
                    </Box>
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                    lineHeight: '30px',
                  }}>
                    Holders:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    <Box component="span">
                      {formatToken(holderCount, 0, 0)}
                    </Box>

                    {!_isProAccount() &&
                      <Tooltip
                        title="Real-time with PRO wallet"
                        placement="top"
                        arrow
                      >
                        <Box component="span" className="living inactive"></Box>
                      </Tooltip>
                    }
                    {_isProAccount() &&
                      <Tooltip
                        title="Real-time"
                        placement="top"
                        arrow
                      >
                        <Box component="span" className="living"></Box>
                      </Tooltip>
                    }

                    <Box component="span" sx={{
                      ml: 1,

                      "& .MuiButtonBase-root": {
                        width: 30,
                        height: 30,
                        transition: "all .3s ease",
                        color: "primary.main",
                        backgroundColor: "white",

                        '&:hover': {
                          color: "secondary.main",
                          background: "#ffffffb3",
                        },

                        '& img': {
                          height: '100%',
                        },
                      },
                    }}>
                      <Tooltip
                        title="Check on bscscan"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          target="_blank"
                          href={`https://bscscan.com/token/${tokenAddress}#balances`}
                        >
                          <CardMedia
                            component="img"
                            src={bscscan}
                            alt="Bscscan"
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>

                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Total transactions:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>

                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/*
            WIP
            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Total profits:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt" sx={{
                  display: 'flex',
                }}>
                  <Typography variant="body2" sx={{
                    fontSize: 14,
                    lineHeight: '30px',
                    fontWeight: 'bold',
                    display: 'none', // WIP
                  }} color="success.main">
                    $29,682.71
                  </Typography>
                  <Chip label="121.12 BNB" color="success" size="small" sx={{
                    // ml: 1, // WIP
                  }} />

                </Grid>
              </Grid>
            </Box>
            */}
          </Card>

          <Card variant="outlined" sx={{
            mt: {
              xs: 2,
              sm: '18px',
            },
          }}>
            <CardContent>
              <Typography variant="body2" sx={{
                fontWeight: 'bold',
                display: 'inline-block',
              }}>
                Profile Summary
              </Typography>
            </CardContent>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Contract address:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" className="text-truncate" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    {tokenAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Total Supply:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    <Box component="span">
                      {formatToken(info.totalSupply, 0)} <strong>{info.symbol}</strong>
                    </Box>
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    Decimals:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                  }}>
                    {info.tokenDecimals}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{
              py: 1,
              px: 2,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" sx={{
                    color: "#12161c",
                    fontSize: 14,
                    lineHeight: {
                      sm: '30px',
                    },
                  }}>
                    Links:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="xs-no-pt">
                  <Box sx={{
                    lineHeight: {
                      sm: '30px'
                    },

                    "& .MuiButtonBase-root": {
                      width: 30,
                      height: 30,
                      transition: "all .3s ease",
                      color: "primary.main",
                      backgroundColor: "white",

                      '&:hover': {
                        color: "secondary.main",
                        background: "#ffffffb3",
                      },

                      '& img': {
                        height: '100%',
                      },
                    },
                  }}>
                    <Tooltip
                      title="Buy on PancakeSwap"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        target="_blank"
                        href={`https://pancakeswap.finance/swap?outputCurrency=${tokenAddress}`}
                      >
                        <CardMedia
                          component="img"
                          src={pancakeswapCakeLogo}
                          alt="Pancakeswap Cake Logo"
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Chart on DexScreener"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        target="_blank"
                        href={`https://dexscreener.com/bsc/${tokenAddress}`}
                      >
                        <CardMedia
                          component="img"
                          src={DexScreenerLogo}
                          alt="DexScreener Logo"
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Chart on PooCoin"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        target="_blank"
                        href={`https://poocoin.app/tokens/${tokenAddress}`}
                      >
                        <CardMedia
                          component="img"
                          src={poocoinLogo}
                          alt="PooCoin Logo"
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Check on bscscan"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        target="_blank"
                        href={`https://bscscan.com/token/${tokenAddress}`}
                      >
                        <CardMedia
                          component="img"
                          src={bscscan}
                          alt="Bscscan"
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>

        </Grid>
        <Grid item xs={12} md={6}>

          <Audit
            safu={safu}
          />

        </Grid>
      </Grid>
    </Box >
  );
}
