// Packages
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Library
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectPaiBalance,
} from 'app/paiSlice';
import {
  selectAddress,
  selectIsConnected,
} from 'app/connectedWalletSlice';



export default function Audit(props) {
  const {
    tokenAddress,
  } = useParams();

  const {
    safu,
  } = props;



  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const isHoneypot = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].is_honeypot) {
      if (safu.result[tokenAddress.toLowerCase()].is_honeypot === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isOpenSource = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].is_open_source) {
      if (safu.result[tokenAddress.toLowerCase()].is_open_source === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isMintable = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].is_mintable) {
      if (safu.result[tokenAddress.toLowerCase()].is_mintable === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isOwnershipRenounced = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].owner_address) {
      if (safu.result[tokenAddress.toLowerCase()].owner_address === "0x0000000000000000000000000000000000000000") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isOwnerChangeBalance = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].owner_change_balance) {
      if (safu.result[tokenAddress.toLowerCase()].owner_change_balance === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isSlippageModifiable = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].slippage_modifiable) {
      if (safu.result[tokenAddress.toLowerCase()].slippage_modifiable === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isTransferPausable = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].transfer_pausable) {
      if (safu.result[tokenAddress.toLowerCase()].transfer_pausable === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isAntiWhale = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].is_anti_whale) {
      if (safu.result[tokenAddress.toLowerCase()].is_anti_whale === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  const isBlacklisted = () => {
    let
      result = null;

    if (safu && safu.result && safu.result[tokenAddress.toLowerCase()] && safu.result[tokenAddress.toLowerCase()].is_blacklisted) {
      if (safu.result[tokenAddress.toLowerCase()].is_blacklisted === "1") {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }



  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body2" sx={{
          display: 'inline-block',
          lineHeight: {
            md: '24px',
          },
        }}>
          <Box component="span" sx={{
            '& span.bnb': {
              color: "#77838f",
              fontSize: 11,
              ml: 0.5,
            },
          }}>
            <Box component="span" sx={{
              fontWeight: 'bold',
            }}>
              Audit
            </Box>
            <Box component="span" className="bnb">
              Powered by <Link sx={{
                color: "#77838f",
              }} href="https://gopluslabs.io/" target="_blank">Go+ Security</Link>
            </Box>
          </Box>

          {!_isProAccount() &&
            <Tooltip
              title="Real-time with PRO wallet"
              placement="top"
              arrow
            >
              <Box component="span" className="living inactive"></Box>
            </Tooltip>
          }
          {_isProAccount() &&
            <Tooltip
              title="Real-time"
              placement="top"
              arrow
            >
              <Box component="span" className="living"></Box>
            </Tooltip>
          }

        </Typography>
      </CardContent>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Honeypot:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isHoneypot() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isHoneypot() !== null && !isHoneypot() &&
              <Chip label="No" color="success" size="small" />
            }
            {isHoneypot() !== null && isHoneypot() &&
              <Chip label="POTENTIAL HONEYPOT" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Verified Contract:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isOpenSource() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isOpenSource() !== null && !isOpenSource() &&
              <Chip label="No" color="warning" size="small" />
            }
            {isOpenSource() !== null && isOpenSource() &&
              <Chip label="Yes" color="success" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Mintable:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isMintable() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isMintable() !== null && !isMintable() &&
              <Chip label="No" color="success" size="small" />
            }
            {isMintable() !== null && isMintable() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Ownership Renounced:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isOwnershipRenounced() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isOwnershipRenounced() !== null && !isOwnershipRenounced() &&
              <Chip label="No" color="warning" size="small" />
            }
            {isOwnershipRenounced() !== null && isOwnershipRenounced() &&
              <Chip label="Yes" color="success" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Balances Modifiable:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isOwnerChangeBalance() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isOwnerChangeBalance() !== null && !isOwnerChangeBalance() &&
              <Chip label="No" color="success" size="small" />
            }
            {isOwnerChangeBalance() !== null && isOwnerChangeBalance() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Tax Modifiable:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isSlippageModifiable() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isSlippageModifiable() !== null && !isSlippageModifiable() &&
              <Chip label="No" color="success" size="small" />
            }
            {isSlippageModifiable() !== null && isSlippageModifiable() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Transfers Pausable:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isTransferPausable() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isTransferPausable() !== null && !isTransferPausable() &&
              <Chip label="No" color="success" size="small" />
            }
            {isTransferPausable() !== null && isTransferPausable() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Anti-Whale Mechanism:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isAntiWhale() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isAntiWhale() !== null && !isAntiWhale() &&
              <Chip label="No" color="success" size="small" />
            }
            {isAntiWhale() !== null && isAntiWhale() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{
        py: 1,
        px: 2,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,
              lineHeight: {
                sm: '24px',
              },
            }}>
              Blacklist:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={8}>
            {isBlacklisted() === null &&
              <Chip label="Undefined" size="small" />
            }
            {isBlacklisted() !== null && !isBlacklisted() &&
              <Chip label="No" color="success" size="small" />
            }
            {isBlacklisted() !== null && isBlacklisted() &&
              <Chip label="Yes" color="warning" size="small" />
            }
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
