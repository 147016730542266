// Packages
import { useEffect, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from "@mui/material/CircularProgress";
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';

// Components
import GroupItem from 'components/pages/home/GroupItem';

// Services
import {
  ProfitAiBotTokensGet,
} from 'services/ProfitAiBotToken';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GroupTabs() {
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [latestAddedGroups, setLatestAddedGroups] = useState([]);

  const fetchLatestAddedGroups = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "date DESC",
          "limit": 10,
        },
        ProfitAiBotTokens = await ProfitAiBotTokensGet(opt);

      if (ProfitAiBotTokens.error) {
        // Catch error and set error message
        setError(ProfitAiBotTokens.error);
      } else {
        setLatestAddedGroups(ProfitAiBotTokens);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchLatestAddedGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Card variant="outlined" sx={{
      mt: 1,
      position: 'relative',
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} sx={{
          '& button': {
            textTransform: 'none',
          },
        }}>
          <Tab label="Top Trending (comming soon...)" {...a11yProps(0)} />
          <Tab label="Latest Added Group" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Box sx={{
          my: 6,
          textAlign: 'center',
        }}>
          Comming soon...
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        {error && error.message &&
          <Box sx={{
            my: 6,
            textAlign: 'center',
          }}>
            {error.message}
          </Box>
        }

        {!error && fetching &&
          <Box sx={{
            py: 6,
            textAlign: 'center',
          }}>
            <CircularProgress />
          </Box>
        }

        {!error && !fetching && latestAddedGroups.length === 0 &&
          <Box sx={{
            py: 6,
            textAlign: 'center',
          }}>
            Not found any groups.
          </Box>
        }

        {!error && !fetching && latestAddedGroups.length > 0 &&
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Token</TableCell>
                  <TableCell>Token Address</TableCell>
                  <TableCell>Group Name</TableCell>
                  <TableCell align="right">Group Members</TableCell>
                  <TableCell>Date Time (UTC)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{
                '& p': {
                  fontSize: 14,
                },
              }}>
                {latestAddedGroups.map((latestAddedGroup) => (
                  <GroupItem
                    key={latestAddedGroup.id}
                    latestAddedGroup={latestAddedGroup}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </TabPanel>
    </Card>
  );
}
