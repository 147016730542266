// Packages
import { useState } from 'react';
import * as React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Icons
import KeyIcon from '@mui/icons-material/Key';



export default function ShowPrivateKey(props) {
  const {
    botWallet,
  } = props;



  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
    <React.Fragment>
      <Button variant="contained" color="warning" size="small" startIcon={<KeyIcon />} onClick={handleOpen}>
        Show Private Key
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Your bot wallet private key
        </DialogTitle>

        <DialogContent sx={{
          '& > :not(style)': {
            mt: 2,
          },
        }}>

          {botWallet.botWalletPrivateKey}

        </DialogContent>
        <DialogActions sx={{
          m: 2,
        }}>
          <Button onClick={handleClose} size="small">
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}
