// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Services
import {
  PAIMemeContestsGet,
} from 'services/PAIMemeContests';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';

// Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Components
import MemeContestItem from 'components/pages/governance/MemeContestItem';



const VOTE_ENABLE = false;



function RenderStatus(props) {
  const { myTotalVotes } = props;

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);



  const connectedWalletSignedData = useSelector(selectSignedData);



  const paiBalance = useSelector(selectPaiBalance);



  if (!VOTE_ENABLE) {
    return <Chip label="Ended" size="small" />;
  }

  if (!connectedWalletIsConnected || !connectedWalletAddress) {
    return <Chip label="Please connect and sign your wallet to vote" color="warning" size="small" />;
  }

  if (paiBalance < 10) {
    return <Chip label="You need to hold at least 10 PAI to vote" color="warning" size="small" />;
  }

  if (!connectedWalletSignedData) {
    return <Chip label="Please sign your wallet to vote" color="warning" size="small" />;
  }

  // Limit max 3 votes per wallet
  if (myTotalVotes >= 3) {
    return <Chip label="Max 3 votes per wallet" color="warning" size="small" />;
  }

  return <Chip icon={<ThumbUpIcon />} label="You can vote now" color="primary" size="small" />;
}



export default function MemeContest() {
  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);



  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [memeContests, setMemeContests] = useState([]);

  const fetchMemeContests = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "include": [
            "paiMemeContestVotes"
          ],
        },
        PAIMemeContests = await PAIMemeContestsGet(opt);

      if (PAIMemeContests.error) {
        // Catch error and set error message
        setError(PAIMemeContests.error);
      } else {
        setMemeContests(PAIMemeContests);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchMemeContests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getTotalVotes = () => {
    let
      count = 0;

    memeContests.forEach(memeContest => {
      if (memeContest.paiMemeContestVotes) {
        count += memeContest.paiMemeContestVotes.length;
      }
    });

    return count;
  }



  const getMyTotalVotes = () => {
    // Did not logged in
    if (!connectedWalletIsConnected && !connectedWalletAddress) {
      return 0;
    }

    let
      count = 0;

    memeContests.forEach(memeContest => {
      if (memeContest.paiMemeContestVotes) {
        const find = memeContest.paiMemeContestVotes.find(x => x.walletAddress === connectedWalletAddress);

        if (find) {
          count += 1;
        }
      }
    });

    return count;
  }



  const memeContestsSortByVote = () => {
    const sortByVotes = memeContests.slice(0);

    const hasVoted = [];

    sortByVotes.forEach(sortByVote => {
      if (sortByVote.paiMemeContestVotes) {
        sortByVote.voted = sortByVote.paiMemeContestVotes.length;
        hasVoted.push(sortByVote);
      }
    });

    hasVoted.sort(function (a, b) {
      return b.voted - a.voted;
    });

    return hasVoted;
  }



  if (error) {
    return (
      <Box sx={{
        mt: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }



  return (
    <Box component="main" sx={{
      pb: 6,
    }}>
      <Container>
        <Box sx={{
          display: 'flex',
          py: 2,
        }}>
          <Box sx={{
            flex: 1,
          }}>
            <Typography variant="body2" sx={{
              lineHeight: '30px',
              fontSize: 21,

              '& span': {
                color: 'grey',
                fontSize: '17px',
                ml: 1,
              },
            }}>
              Meme Contest
              <span>Governance</span>
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Grid container spacing={2} sx={{
          mt: 2,

          '& .xs-no-pt': {
            pt: {
              xs: '0 !important',
              sm: '16px !important',
            },
          },
        }}>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Overview
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Total entries:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className="xs-no-pt">
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      {memeContests.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Total votes:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className="xs-no-pt">
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      {getTotalVotes()}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <React.Fragment>
                <Divider />

                <Box sx={{
                  py: 1,
                  px: 2,
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" sx={{
                        color: "#12161c",
                        fontSize: 14,
                        lineHeight: {
                          md: '24px',
                        },
                      }}>
                        Your votes:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} className="xs-no-pt">
                      <Typography variant="body2" className="text-truncate" sx={{
                        color: "#12161c",
                        fontSize: 14,
                        lineHeight: {
                          md: '24px',
                        },
                      }}>
                        {getMyTotalVotes()}/3
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Instruction
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Winners:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className="xs-no-pt">
                    <Typography variant="body2" className="text-truncate" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Top 3 members get highest votes
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Min hold to vote:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className="xs-no-pt">
                    <Typography variant="body2" className="text-truncate" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      10 <strong>PAI</strong>, max 3 votes per wallet
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className="xs-no-pt">
                    <RenderStatus myTotalVotes={getMyTotalVotes()} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>



        {/* Top entries */}
        <Box sx={{
          display: 'flex',
          py: 2,
          mt: 4,
        }}>
          <Box sx={{
            flex: 1,
          }}>
            <Typography variant="body2" sx={{
              lineHeight: '30px',
              fontSize: 21,

              '& span': {
                color: 'grey',
                fontSize: '17px',
                ml: 1,
              },
            }}>
              Top entries
            </Typography>
          </Box>
        </Box>

        <Divider />



        {memeContestsSortByVote().length > 0 &&
          <Grid container spacing={2} sx={{
            mt: 2,

            '& .MuiCardContent-root': {
              pt: '6px',
              pb: '6px',
            }
          }}>
            {memeContestsSortByVote().map((memeContest) => (
              <MemeContestItem key={memeContest.id} memeContest={memeContest} fetchMemeContests={fetchMemeContests} myTotalVotes={getMyTotalVotes()} />
            ))}
          </Grid>
        }
        {/* Top entries */}



        {/* All entries */}
        <Box sx={{
          display: 'flex',
          py: 2,
          mt: 4,
        }}>
          <Box sx={{
            flex: 1,
          }}>
            <Typography variant="body2" sx={{
              lineHeight: '30px',
              fontSize: 21,

              '& span': {
                color: 'grey',
                fontSize: '17px',
                ml: 1,
              },
            }}>
              All entries
            </Typography>
          </Box>
        </Box>

        <Divider />



        {/* Empty */}
        {memeContests.length === 0 &&
          <Box sx={{
            py: 6,
            textAlign: 'center',
          }}>
            Not found any entries.
          </Box>
        }



        {memeContests.length > 0 &&
          <Grid container spacing={2} sx={{
            mt: 2,

            '& .MuiCardContent-root': {
              pt: '6px',
              pb: '6px',
            }
          }}>
            {memeContests.map((memeContest) => (
              <MemeContestItem key={memeContest.id} memeContest={memeContest} fetchMemeContests={fetchMemeContests} myTotalVotes={getMyTotalVotes()} />
            ))}
          </Grid>
        }
        {/* All entries */}



      </Container>
    </Box>
  );
}
