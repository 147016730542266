// Packages
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';

// Store
import {
  selectAddress,
  selectSignedData,
} from 'app/connectedWalletSlice';

// Services
import {
  PaiWalletTrackingsDelete,
} from 'services/PaiWalletTrackings';



export default function Delete(props) {
  const {
    fetchWalletTrackings,
    walletTracking,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fetching, setFetching] = useState();

  const onSubmit = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const
        PAIWalletTracking = await PaiWalletTrackingsDelete(connectedWalletAddress, connectedWalletSignedData, walletTracking.id);

      if (PAIWalletTracking.error && PAIWalletTracking.error.message) {
        // Catch error and set error message
        console.error(PAIWalletTracking.error);
        enqueueSnackbar(PAIWalletTracking.error.message, { variant: 'warning' });
      } else if (PAIWalletTracking.success && PAIWalletTracking.success.message) {
        enqueueSnackbar(PAIWalletTracking.success.message);
        await fetchWalletTrackings();
      }

      // Turn off fetching
      setFetching(false);

      // Close the popup
      handleClose();
    }
  }



  return (
    <React.Fragment>
      <Button variant="contained" color="error" size="small" startIcon={<DeleteIcon />} onClick={handleOpen} sx={{
        ml: 1,
      }}>
        Delete
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Confirmation Required
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you wish to unlink the address {walletTracking.trackingWalletAddress}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          m: 2,
        }}>
          <Button onClick={handleClose} size="small">
            Close
          </Button>
          <Button variant="contained" size="small" type="submit" disabled={fetching} onClick={onSubmit} autoFocus color="error">
            Continue
          </Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}
