// Packages
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FunctionsIcon from '@mui/icons-material/Functions';
import HomeIcon from '@mui/icons-material/Home';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import StarBorder from '@mui/icons-material/StarBorder';

// Store
import {
  selectAddress,
} from 'app/connectedWalletSlice';

// Library
import {
  isMasterAccount,
} from 'libraries/Master';



function DrawerItem(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    to,
    title,
    icon,
    setMenuOpen,
    sub,
  } = props;

  let selected = false;

  if (location.pathname === to) {
    selected = true;
  }

  return (
    <ListItemButton
      selected={selected}
      onClick={() => {
        navigate(to);
        setMenuOpen(false);
      }}
      sx={{
        pl: sub ? 4 : 2,
      }}
    >
      <ListItemIcon>
        {React.createElement(icon)}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
}



export default function MenuMobile() {
  const connectedWalletAddress = useSelector(selectAddress);



  const _isMasterAccount = () => {
    return isMasterAccount(connectedWalletAddress);
  }



  const [menuOpen, setMenuOpen] = useState(false);

  const [open, setOpen] = useState(true);
  const [openFunction, setOpenFunction] = useState(true);
  const [openSecretFunction, setOpenSecretFunction] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickFunction = () => {
    setOpenFunction(!openFunction);
  };

  const handleClickSecretFunction = () => {
    setOpenSecretFunction(!openSecretFunction);
  };

  return (
    <Box sx={{
      display: {
        xs: 'block',
        md: 'none',
      },
    }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}>
        <MenuIcon />
      </IconButton>

      <Drawer
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}>
        <List sx={{
          width: 240,
        }}>

          <DrawerItem setMenuOpen={setMenuOpen} icon={HomeIcon} to="/" title="Home" />

          <Divider />

          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Governances" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/governances/meme-contest" title="Meme contest" sub={true} />
            </List>
          </Collapse>

          <Divider />

          <ListItemButton onClick={handleClickFunction}>
            <ListItemIcon>
              <FunctionsIcon />
            </ListItemIcon>
            <ListItemText primary="Functions" />
            {openFunction ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFunction} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/functions/wallet-tracking" title="Wallet Tracking" sub={true} />
              <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/functions/telegram-connection" title="Telegram Connection" sub={true} />
              <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/functions/bot-wallet" title="Bot Wallet" sub={true} />
            </List>
          </Collapse>

          {_isMasterAccount() &&
            <React.Fragment>
              <Divider />

              <ListItemButton onClick={handleClickSecretFunction}>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primary="Secret functions" />
                {openSecretFunction ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openSecretFunction} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/secret-functions/sniper" title="Sniper" sub={true} />
                  <DrawerItem setMenuOpen={setMenuOpen} icon={StarBorder} to="/secret-functions/crazy-dev" title="Crazy Dev" sub={true} />
                </List>
              </Collapse>
            </React.Fragment>
          }

        </List>
      </Drawer>
    </Box>
  );
}
