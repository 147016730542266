// Packages
import {
  Link,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Components
import BiggestProfits from 'components/pages/token/BiggestProfits';
import LatestProfits from 'components/pages/token/LatestProfits';
import Transactions from 'components/pages/token/Transactions';
import WalletFilter from 'components/pages/token/WalletFilter';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function TokenTabs(props) {
  const { tokenAddress } = useParams();

  const {
    info,
    tokenPrice,
  } = props;

  const routeMatch = useRouteMatch(['/token/:tokenAddress', '/token/:tokenAddress/latest-profits', '/token/:tokenAddress/transactions']);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Card variant="outlined" sx={{
      mt: 2,
      position: 'relative',
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} sx={{
          '& a': {
            textTransform: 'none',
          },
        }}>
          <Tab label="Biggest Profits [PRO]" value="/token/:tokenAddress" to={`/token/${tokenAddress}`} component={Link} />
          <Tab label="Latest Profits" value="/token/:tokenAddress/latest-profits" to={`/token/${tokenAddress}/latest-profits`} component={Link} />
          <Tab label="Transactions" value="/token/:tokenAddress/transactions" to={`/token/${tokenAddress}/transactions`} component={Link} />
        </Tabs>
      </Box>

      <WalletFilter />

      <TabPanel>
        {currentTab === "/token/:tokenAddress" &&
          <BiggestProfits />
        }
        {currentTab === "/token/:tokenAddress/latest-profits" &&
          <LatestProfits />
        }
        {currentTab === "/token/:tokenAddress/transactions" &&
          <Transactions
            info={info}
            tokenPrice={tokenPrice}
          />
        }
      </TabPanel>
    </Card>
  );
}
