// Packages / Modules
import Web3 from 'web3';

// Services
import {
  dexscreenerGetTokenInfo,
} from 'services/Dexscreener';

const web3 = new Web3(process.env.REACT_APP_WEB3_PROVIDER);

// The others config
const tokenAbi = [
  { "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "pure", "type": "function" },
  { "inputs": [], "name": "symbol", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "pure", "type": "function" },
  { "inputs": [], "name": "owner", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
  { "inputs": [{ "internalType": "address", "name": "account", "type": "address" }], "name": "balanceOf", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
];
const pancakeSwapAbi = [
  { "inputs": [{ "internalType": "uint256", "name": "amountIn", "type": "uint256" }, { "internalType": "address[]", "name": "path", "type": "address[]" }], "name": "getAmountsOut", "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }], "stateMutability": "view", "type": "function" },
];
const pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();



export async function getTokenInfo(address) {
  try {
    if (web3.utils.isAddress(address)) {
      const tokenRouter = new web3.eth.Contract(tokenAbi, address);

      const tokenDecimals = await tokenRouter.methods.decimals().call();
      const totalSupply = await tokenRouter.methods.totalSupply().call();
      const name = await tokenRouter.methods.name().call();
      const symbol = await tokenRouter.methods.symbol().call();

      let tSupply = null;
      if (totalSupply && tokenDecimals) {
        tSupply = totalSupply.substr(0, totalSupply.length - tokenDecimals);
      }

      // Cake-LP
      if (name === "Pancake LPs" && symbol === "Cake-LP") {
        return false;
      }

      return {
        address: web3.utils.toChecksumAddress(address),
        tokenDecimals: tokenDecimals,
        totalSupply: tSupply,
        name: name,
        symbol: symbol,
      };
    }

    return false;
  } catch (e) {
    console.log(e.toString());
    return false;
  }
}

export async function isBscTokenAddress(address) {
  if (web3.utils.isAddress(address)) {
    return web3.utils.toChecksumAddress(address);
  } else {
    return false;
  }
}

export function getHumanWeiBalance(value) {
  const weiBalance = web3.utils.fromWei(value);
  const humanWeiBalance = parseFloat(weiBalance).toFixed(9);
  return humanWeiBalance
}

export async function getBnbBalance(walletAddress) {
  const balance = await web3.eth.getBalance(walletAddress);
  const humanWeiBalance = getHumanWeiBalance(balance);

  return humanWeiBalance;
}

export async function getBalance(tokenAddress, walletAddress) {
  const tokenRouter = new web3.eth.Contract(tokenAbi, tokenAddress);

  const tokenDecimals = await tokenRouter.methods.decimals().call();

  // Check balance
  const balance = await tokenRouter.methods.balanceOf(walletAddress).call();

  return balance / 10 ** tokenDecimals;
}



export async function calcBnbPrice() {
  const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" // BNB
  // const USDTokenAddress = "0x55d398326f99059fF775485246999027B3197955" // USDT
  const USDTokenAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" // BUSD
  const bnbToSell = web3.utils.toWei("1", "ether");
  let amountOut;
  try {
    let router = new web3.eth.Contract(pancakeSwapAbi, pancakeSwapContract);
    amountOut = await router.methods.getAmountsOut(bnbToSell, [BNBTokenAddress, USDTokenAddress]).call();
    amountOut = web3.utils.fromWei(amountOut[1]);
  } catch (error) { }
  if (!amountOut) return 0;
  return amountOut;
}



function setDecimals(number, decimals) {
  number = number.toString();
  let numberAbs = number.split('.')[0]
  let numberDecimals = number.split('.')[1] ? number.split('.')[1] : '';
  while (numberDecimals.length < decimals) {
    numberDecimals += "0";
  }
  return numberAbs + numberDecimals;
}

async function calcSell(tokensToSell, tokenAddres) {
  const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" // BNB

  let tokenRouter = new web3.eth.Contract(tokenAbi, tokenAddres);
  let tokenDecimals = await tokenRouter.methods.decimals().call();

  tokensToSell = setDecimals(tokensToSell, tokenDecimals);
  let amountOut;
  try {
    let router = new web3.eth.Contract(pancakeSwapAbi, pancakeSwapContract);
    amountOut = await router.methods.getAmountsOut(tokensToSell, [tokenAddres, BNBTokenAddress]).call();
    amountOut = web3.utils.fromWei(amountOut[1]);
  } catch (error) { }

  if (!amountOut) return 0;
  return amountOut;
}

export async function calcTokenPrice(tokenAddress) {
  const tokens_to_sell = 1;
  const priceInBnb = await calcSell(tokens_to_sell, tokenAddress) / tokens_to_sell; // calculate TOKEN

  if (!priceInBnb) {
    // Try to get price from dexscreener
    const dexscreenerInfo = await dexscreenerGetTokenInfo(tokenAddress);

    if (dexscreenerInfo && dexscreenerInfo.pairs && dexscreenerInfo.pairs.length) {
      const firstPair = dexscreenerInfo.pairs[0];

      if (firstPair && firstPair.priceNative) {
        return parseFloat(firstPair.priceNative);
      }
    }
  }
  return priceInBnb;
}



function encodeBasicFunction(web3, funcName) {
  return web3.eth.abi.encodeFunctionCall({
    name: funcName,
    type: 'function',
    inputs: []
  }, []);
}



async function getMaxes(tokenAddress) {
  let sig = web3.eth.abi.encodeFunctionSignature({
    name: '_maxTxAmount',
    type: 'function',
    inputs: []
  });

  let d = {
    to: tokenAddress,
    from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
    value: 0,
    gas: 15000000,
    data: sig,
  };

  let
    maxTXAmount = 0,
    maxSell = 0;

  try {
    const val = await web3.eth.call(d);
    maxTXAmount = web3.utils.toBN(val);
  } catch (e) {
    // I will nest as much as I want. screw javascript.
    sig = web3.eth.abi.encodeFunctionSignature({
      name: 'maxSellTransactionAmount',
      type: 'function',
      inputs: []
    });
    d = {
      to: tokenAddress,
      from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
      value: 0,
      gas: 15000000,
      data: sig,
    };
    try {
      const val2 = await web3.eth.call(d);
      maxSell = web3.utils.toBN(val2);
    } catch (e) {
    }
  }

  return {
    maxTXAmount: maxTXAmount,
    maxSell: maxSell,
  }
}



async function getBNBIn(tokenAddress) {
  const
    maxes = await getMaxes(tokenAddress),
    maxTXAmount = maxes.maxTXAmount,
    maxSell = maxes.maxSell;

  let
    amountIn = maxTXAmount;
  if (maxSell !== 0) {
    amountIn = maxSell;
  }

  const
    WETH = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    path = [tokenAddress, WETH],
    sig = web3.eth.abi.encodeFunctionCall({
      name: 'getAmountsOut',
      type: 'function',
      inputs: [{
        type: 'uint256',
        name: 'amountIn'
      }, {
        type: 'address[]',
        name: 'path'
      },],
      outputs: [{
        type: 'uint256[]',
        name: 'amounts'
      },],
    }, [amountIn, path]),
    d = {
      to: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
      from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
      value: 0,
      gas: 15000000,
      data: sig,
    };

  let
    bnbIn = 1000000000000000000;

  try {
    const
      val = await web3.eth.call(d),
      decoded = web3.eth.abi.decodeParameter('uint256[]', val);
    bnbIn = web3.utils.toBN(decoded[1]);
  } catch (e) {
    // console.error(e);
  }

  return {
    maxTXAmount: maxTXAmount,
    maxSell: maxSell,
    bnbIn: bnbIn,
  };
}



async function getTotalSupply(tokenAddress) {
  try {
    if (web3.utils.isAddress(tokenAddress)) {
      const tokenRouter = new web3.eth.Contract(tokenAbi, tokenAddress);

      const totalSupply = await tokenRouter.methods.totalSupply().call();

      return totalSupply;
    }

    return false;
  } catch (e) {
    console.log(e.toString());
    return false;
  }
}



async function getDecimals(tokenAddress) {
  const
    sig = encodeBasicFunction(web3, 'decimals'),
    d = {
      to: tokenAddress,
      from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
      value: 0,
      gas: 15000000,
      data: sig,
    };

  let
    tokenDecimals;

  try {
    const
      val = await web3.eth.call(d);
    tokenDecimals = web3.utils.hexToNumber(val);
  } catch (e) {
    console.error('decimals', e);
  }

  return tokenDecimals;
}



export async function getTokenAnalyse(tokenAddress) {
  try {
    const
      tokenPrice = await calcTokenPrice(tokenAddress),
      encodedAddress = web3.eth.abi.encodeParameter('address', tokenAddress),
      contractFuncData = '0xd66383cb',
      callData = contractFuncData + encodedAddress.substring(2),
      totalSupply = await getTotalSupply(tokenAddress),
      tokenDecimals = await getDecimals(tokenAddress),
      _bnbIn = await getBNBIn(tokenAddress),
      maxTXAmount = _bnbIn.maxTXAmount,
      maxSell = _bnbIn.maxSell,
      bnbIn = _bnbIn.bnbIn;

    let
      tokens = totalSupply.substr(0, totalSupply.length - tokenDecimals),
      val = 100000000000000000,
      buyTax,
      sellTax,
      buyGasUsed,
      sellGasUsed,
      error;

    if (bnbIn < val) {
      val = bnbIn - 1000;
    }

    if (tokenAddress.toLowerCase() === '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c') {
      // BNB
      buyTax = 0;
      sellTax = 0;
    } else {
      // Max TX
      if (maxTXAmount !== 0 || maxSell !== 0) {
        let
          x = maxTXAmount;
        if (maxSell !== 0) {
          x = maxSell;
        }

        tokens = x.toString().substr(0, x.toString().length - tokenDecimals);

        if (parseFloat(tokens) > parseFloat(totalSupply.substr(0, totalSupply.length - tokenDecimals))) {
          tokens = totalSupply.substr(0, totalSupply.length - tokenDecimals);
        }
      }

      try {
        await web3.eth.call({
          to: '0x2bf75fd2fab5fc635a4c6073864c708dfc8396fc',
          from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
          value: val,
          gas: 45000000,
          data: callData,
        }).then((val) => {
          const
            decoded = web3.eth.abi.decodeParameters(['uint256', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256'], val),
            buyExpectedOut = web3.utils.toBN(decoded[0]),
            buyActualOut = web3.utils.toBN(decoded[1]),
            sellExpectedOut = web3.utils.toBN(decoded[2]),
            sellActualOut = web3.utils.toBN(decoded[3]);

          buyGasUsed = web3.utils.toBN(decoded[4]);
          sellGasUsed = web3.utils.toBN(decoded[5]);

          buyTax = Math.round((buyExpectedOut - buyActualOut) / buyExpectedOut * 100 * 10) / 10;
          sellTax = Math.round((sellExpectedOut - sellActualOut) / sellExpectedOut * 100 * 10) / 10;
        });
      } catch (e) {
        error = e.toString();
        console.error(e.toString());
      }
    }

    return {
      tokenAddress: tokenAddress,
      tokenPrice: tokenPrice,
      maxTXAmount: tokens,
      totalSupply: totalSupply.substr(0, totalSupply.length - tokenDecimals),
      tokenDecimals: tokenDecimals,
      buyTax: buyTax,
      sellTax: sellTax,
      buyGasUsed: parseInt(buyGasUsed),
      sellGasUsed: parseInt(sellGasUsed),
      error: error,
    };
  } catch (e) {
    console.error(e.toString());
  }
}
