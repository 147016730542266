// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import WalletIcon from '@mui/icons-material/Wallet';

// Components
import Tabs from 'components/pages/wallet/Tabs';

// Const
const ICON_SIZE = "30px";

export default function Wallet() {
  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,
    }}>
      <Container>
        <Box sx={{
          display: 'flex',
          py: 2,
        }}>
          <Box sx={{
            width: ICON_SIZE,
            height: ICON_SIZE,

            '& .MuiSvgIcon-root': {
              width: ICON_SIZE,
              height: ICON_SIZE,
            },
          }}>
            <WalletIcon />
          </Box>
          <Box sx={{
            textAlign: 'left',
            ml: 1,
            flex: 1,
          }}>
            <Typography variant="body2" sx={{
              lineHeight: '30px',
              fontSize: 21,

              '& span': {
                color: 'grey',
                fontSize: '17px',
                ml: 1,
              },
            }}>
              Address
              <span>0xeBD26B9072548998E3Fa3D5577Ee3723565f9Cf8</span>
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Grid container spacing={2} sx={{
          mt: 2,
        }}>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  lineHeight: '31px',
                }}>
                  Overview
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      0.024358901453580049 BNB
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      BNB Value:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,

                      '& span': {
                        color: "#77838f",
                        fontSize: 11,
                      },
                    }}>
                      $5.98 <span>(@ $245.30/BNB)</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                      lineHeight: '24px',
                    }}>
                      Total profits:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{
                    display: 'flex',
                  }}>
                    <Typography variant="body2" sx={{
                      fontSize: 14,
                      lineHeight: '24px',
                      fontWeight: 'bold',
                    }} color="success.main">
                      $301.95
                    </Typography>
                    <Chip label="1.23 BNB" color="success" size="small" sx={{
                      ml: 1,
                    }} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent sx={{
                display: 'flex',
              }}>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  lineHeight: '31px',
                  flex: 1,
                }}>
                  More Info
                </Typography>
                <IconButton  size="small" color="primary">
                  <FavoriteIcon fontSize="inherit" />
                </IconButton>
                <Button variant="contained" size="small" sx={{
                  ml: 1,
                }}>Alert via Telegram</Button>
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      🟢 Total bought:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{
                      fontSize: 14,
                      fontWeight: 'bold',
                    }} color="success.main">
                      3 Txn - 1.23 BNB
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      🔴 Total sold:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{
                      fontSize: 14,
                      fontWeight: 'bold',
                    }} color="error.main">
                      3 Txn - 4.65 BNB
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{
                      color: "#12161c",
                      fontSize: 14,
                    }}>
                      🟡 Total fee:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{
                      fontSize: 14,
                      lineHeight: '24px',
                      fontWeight: 'bold',
                    }}>
                      7 Txn - 0.007 BNB
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Tabs sx={{
          mt: 6,
        }} />
      </Container>
    </Box>
  );
}
