// Packages
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import CircularProgress from "@mui/material/CircularProgress";
import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// Components
import WalletTrackerItem from 'components/pages/token/WalletTrackerItem';

// Services
import {
  PaiBotWalletsGet,
} from 'services/PaiBotWallets';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';

// Library
import {
  isProAccount,
} from 'libraries/Pro';



export default function YourWalletInfo(props) {
  const {
    info,
    tokenAnalyse,
    tokenPrice,
  } = props;

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [botWallets, setBotWallets] = useState(null);

  const fetchBotWallets = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        PaiBotWallets = await PaiBotWalletsGet(connectedWalletAddress, connectedWalletSignedData);

      if (PaiBotWallets.error) {
        // Catch error and set error message
        setError(PaiBotWallets.error);
      } else {
        setBotWallets(PaiBotWallets);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  const getPermission = () => {
    let
      result,
      message;

    if (!connectedWalletIsConnected || !connectedWalletAddress) {
      result = false;
      message = 'Please connect and sign your wallet to use the bot trading function.';
    } else if (!_isProAccount()) {
      result = false;
      message = 'You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions.';
    } else if (!connectedWalletSignedData) {
      result = false;
      message = 'Please sign your wallet to use the bot trading function.';
    } else {
      result = true;
      message = 'You have permission to use the bot trading function';
    }

    return {
      result: result,
      message: message,
    }
  }

  const _getPermission = getPermission();

  useEffect(() => {
    if (_getPermission.result) {
      fetchBotWallets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWalletAddress, connectedWalletSignedData]);

  const isConnectedWalletAddressInBotWallets = () => {
    if (!botWallets) {
      return false;
    }

    let result = false;

    botWallets.every(botWallet => {
      if (botWallet.botWalletAddress === connectedWalletAddress) {
        result = true;
        return false;
      }
      return true;
    });

    return result;
  }



  return (
    <React.Fragment>
      <WalletTrackerItem
        info={info}
        tokenPrice={tokenPrice}
        tokenAnalyse={tokenAnalyse}
        name="YOUR ADDRESS"
        bot={isConnectedWalletAddressInBotWallets()}
      />

      {/* Guest */}
      {!_getPermission.result &&
        <Paper variant="outlined" sx={{
          mt: 2,
          p: 2,
          textAlign: 'center',
        }}>
          <Typography variant="body2">
            {_getPermission.message}
          </Typography>
        </Paper>
      }

      {/* First fetching */}
      {_getPermission.result && fetching && botWallets === null &&
        <Paper variant="outlined" sx={{
          mt: 2,
          p: 2,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Paper>
      }

      {/* Error */}
      {_getPermission.result && !fetching && error &&
        <Paper variant="outlined" sx={{
          mt: 2,
          p: 2,
          textAlign: 'center',
          color: 'secondary.main',
        }}>
          <Typography variant="body2">
            {error.message}
          </Typography>
        </Paper>
      }

      {/* Empty */}
      {_getPermission.result && !fetching && !error && botWallets && botWallets.length === 0 &&
        <Paper variant="outlined" sx={{
          mt: 2,
          p: 2,
          textAlign: 'center',
        }}>
          <Typography variant="body2">
            Not found any wallets.
          </Typography>
        </Paper>
      }

      {/* List */}
      {_getPermission.result && !fetching && !error && botWallets && botWallets.length > 0 &&
        <React.Fragment>
          {botWallets.map(botWallet => {
            if (botWallet.botWalletAddress !== connectedWalletAddress) {
              return <WalletTrackerItem
                key={botWallet.id}
                info={info}
                tokenPrice={tokenPrice}
                tokenAnalyse={tokenAnalyse}
                wallet={botWallet.botWalletAddress}
                name={`${botWallet.description} address`}
                bot={true}
              />
            } else {
              return '';
            }
          })}

          <Typography variant="body2" sx={{
            mt: 1,
            textAlign: 'right',
          }}>
            <Link onClick={fetchBotWallets} sx={{
              cursor: 'pointer',
              textDecoration: 'none',
            }}>
              Reload your Bot Wallets
            </Link>
            {` | `}
            <Link title="Manage your Bot Wallets" component={RouterLink} to={`/functions/bot-wallet`} sx={{
              textDecoration: 'none',
            }}>
              Manage your Bot Wallets
            </Link>
          </Typography>
        </React.Fragment>
      }

    </React.Fragment>
  );
}
