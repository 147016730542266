// Packages
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { useAccount } from 'wagmi'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Components
import Footer from 'components/Footer';
import Header from 'components/header/Header';
import Pro from 'components/Pro';
import Search from 'components/pages/home/Search';

// Pages
import Home from 'components/pages/home';

import Token from 'components/pages/token';

import Wallet from 'components/pages/wallet';

import MemeContest from 'components/pages/governance/MemeContest';

import WalletTracking from 'components/pages/functions/wallet-tracking';
import TelegramConnection from 'components/pages/functions/telegram-connection';
import BotWallet from 'components/pages/functions/bot-wallet';

import Sniper from 'components/pages/secret-functions/sniper';
import CrazyDev from 'components/pages/secret-functions/crazy-dev';

import NotFound from 'components/pages/NotFound';

// Store
import {
  setBnbPrice,
} from 'app/bnbSlice';
import {
  setPaiBalance,
  setPaiPrice,
} from 'app/paiSlice';
import {
  login,
  setSignedData,
} from 'app/connectedWalletSlice';

// Library
import {
  calcBnbPrice,
  calcTokenPrice,
  getBalance,
} from 'libraries/Web3';
import {
  isProAccount,
} from 'libraries/Pro';

// Css
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.scss';

// Store
import {
  selectIsConnected,
  selectAddress,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';



// custom hook for getting previous value
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}



function App() {
  const dispatch = useDispatch();



  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const { isConnected, address } = useAccount();

  const prevAddress = usePrevious(address);

  useEffect(() => {
    dispatch(login({
      isConnected: isConnected,
      address: address,
    }));

    // Reset PAI balance && signed data when log out
    if (!isConnected) {
      dispatch(setPaiBalance(0));
      dispatch(setSignedData(null));
    }

    // Reset signed data when changing the wallet address
    if (prevAddress && address && prevAddress !== address) {
      dispatch(setSignedData(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);



  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setRefresh(Math.random());
    }, 1000 * 5);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const [fetching, setFetching] = useState();

  // declare the data fetching function
  const fetchData = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      try {
        // Get bnbPrice
        const bnbPrice = await calcBnbPrice();
        dispatch(setBnbPrice(bnbPrice));

        // Get PAI balance
        if (isConnected && address) {
          const paiBalance = await getBalance("0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32", address);
          dispatch(setPaiBalance(paiBalance));
        }

        // Get PAI price
        const paiPrice = await calcTokenPrice("0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32");
        dispatch(setPaiPrice(paiPrice));
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, address]);



  return (
    <Box>
      <Header />

      {!_isProAccount() &&
        <Pro />
      }

      <Search />

      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/token/:tokenAddress" element={<Token />} />
        <Route path="/token/:tokenAddress/latest-profits" element={<Token />} />
        <Route path="/token/:tokenAddress/transactions" element={<Token />} />
        <Route path="/token/:tokenAddress/transactions/:wallet" element={<Token />} />

        <Route path="/wallet/:walletAddress" element={<Wallet />} />

        <Route path="/governances/meme-contest" element={<MemeContest />} />

        <Route path="/functions/wallet-tracking" element={<WalletTracking />} />
        <Route path="/functions/telegram-connection" element={<TelegramConnection />} />
        <Route path="/functions/bot-wallet" element={<BotWallet />} />

        <Route path="/secret-functions/sniper" element={<Sniper />} />
        <Route path="/secret-functions/crazy-dev" element={<CrazyDev />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </Box>
  );
}

export default function IntegrationNotistack() {
  const
    notistackRef = useRef();

  return (
    <SnackbarProvider
      maxSnack={6}
      ref={notistackRef}
      action={(snackbarId) => (
        <IconButton onClick={() => { notistackRef.current.closeSnackbar(snackbarId) }} sx={{
          color: 'white',
        }}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <App />
    </SnackbarProvider>
  );
}
