// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Icons
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

// Store
import {
  selectBnbPrice,
} from 'app/bnbSlice';

// Library
import {
  formatDate,
  formatToken,
  getShortAddress,
} from 'libraries/Common';
import {
  getBnbBalance,
  isBscTokenAddress,
} from 'libraries/Web3';

// Components
import Delete from 'components/pages/functions/wallet-tracking/Delete';
import Edit from 'components/pages/functions/wallet-tracking/Edit';



export default function WalletTrackingItem(props) {
  const bnbPrice = useSelector(selectBnbPrice);

  const {
    fetchWalletTrackings,
    walletTracking,
  } = props;

  const time = (new Date(walletTracking.date)).getTime() / 1000;



  const [fetching, setFetching] = useState();
  const [balance, setBalance] = useState(0);

  // declare the data fetching function
  const fetchData = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      try {
        // Get balance
        const address = await isBscTokenAddress(walletTracking.trackingWalletAddress);
        if (address) {
          const bnbBalance = await getBnbBalance(walletTracking.trackingWalletAddress);
          setBalance(bnbBalance);
        }
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0
        },

        '& th, & td': {
          verticalAlign: 'top',
        },
      }}
    >
      <TableCell component="th" scope="row" align-="center" sx={{
        whiteSpace: 'nowrap',
      }}>
        <Edit
          fetchWalletTrackings={fetchWalletTrackings}
          walletTracking={walletTracking}
        />

        <Delete
          fetchWalletTrackings={fetchWalletTrackings}
          walletTracking={walletTracking}
        />
      </TableCell>
      <TableCell>
        <Tooltip
          title={walletTracking.trackingWalletAddress}
          placement="top"
          arrow
        >
          <Typography sx={{
            display: 'inline-block',
          }}>
            {getShortAddress(walletTracking.trackingWalletAddress)}
          </Typography>
        </Tooltip>

        <Typography sx={{
          '& span': {
            color: "#77838f",
            fontSize: 11,
          },
        }}>
          {walletTracking.description &&
            <React.Fragment>
              <Typography variant="span">
                {walletTracking.description}
              </Typography><br />
            </React.Fragment>
          }
          <Typography variant="span">
            Added on {formatDate(time)}
          </Typography>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={{
          '& span': {
            color: "#77838f",
            fontSize: 11,
          },
        }}>
          {formatToken(balance, 3, 3)} BNB <Typography variant="span">(${formatToken(balance * bnbPrice, 2, 2)})
          </Typography>
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{
          display: 'flex',
        }}>
          {walletTracking.notification &&
            <React.Fragment>
              <Tooltip
                title="Notification enabled"
                placement="top"
                arrow
              >
                <NotificationsActiveIcon color="primary" fontSize="inherit" sx={{
                  mt: 0.4,
                  mr: 1,
                }} />
              </Tooltip>
              <Typography variant="span" color="primary">
                On
              </Typography>
            </React.Fragment>
          }
          {!walletTracking.notification &&
            <React.Fragment>
              <Tooltip
                title="Notification disabled"
                placement="top"
                arrow
              >
                <NotificationsOffIcon fontSize="inherit" sx={{
                  mt: 0.4,
                  mr: 1,
                }} />
              </Tooltip>
              <Typography variant="span">
                Off
              </Typography>
            </React.Fragment>
          }
        </Box>
      </TableCell>
      <TableCell sx={{
        whiteSpace: 'nowrap',
      }}>
        <Box sx={{
          display: 'flex',

          '& span': {
            color: "#77838f",
            fontSize: 11,
          },
        }}>
          {walletTracking.copyBuyEnable &&
            <Typography color="primary">
              On<br />
              {walletTracking.copyBuyAmount === 0 &&
                <Typography variant="span">0 BNB</Typography>
              }
              {walletTracking.copyBuyAmount !== 0 &&
                <Typography variant="span">{formatToken(walletTracking.copyBuyAmount, 3, 3)} BNB</Typography>
              }
            </Typography>
          }
          {!walletTracking.copyBuyEnable &&
            <Typography>
              Off
            </Typography>
          }
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{
          display: 'flex',
        }}>
          {walletTracking.copySellEnable &&
            <Typography color="primary">
              On
            </Typography>
          }
          {!walletTracking.copySellEnable &&
            <Typography>
              Off
            </Typography>
          }
        </Box>
      </TableCell>
    </TableRow>
  );
}
