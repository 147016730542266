// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Icons
import CachedIcon from '@mui/icons-material/Cached';
import InfoIcon from '@mui/icons-material/Info';

// Services
import {
  PaiWalletTrackingsGet,
} from 'services/PaiWalletTrackings';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';

// Components
import Add from 'components/pages/functions/wallet-tracking/Add';
import Nav from 'components/pages/functions/Nav';
import WalletTrackingItem from 'components/pages/functions/wallet-tracking/WalletTrackingItem';



function WalletTrackingHeader(props) {
  const {
    fetchWalletTrackings,
    _getPermission,
    sx,
  } = props;

  return (
    <CardContent sx={sx}>
      <Typography variant="body2" sx={{
        fontWeight: 'bold',
        display: 'inline-block',
      }}>
        Wallet Tracking
      </Typography>

      <Button variant="outlined" size="small" startIcon={<CachedIcon />} sx={{
        ml: 2,
      }}
        onClick={() => {
          fetchWalletTrackings();
        }}
        disabled={!_getPermission.result}
      >
        Reload
      </Button>

      <Add
        _getPermission={_getPermission}
        fetchWalletTrackings={fetchWalletTrackings}
      />
    </CardContent>
  );
}



export default function WalletTracking() {
  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [walletTrackings, setWalletTrackings] = useState(null);

  const fetchWalletTrackings = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        PAIWalletTrackings = await PaiWalletTrackingsGet(connectedWalletAddress, connectedWalletSignedData);

      if (PAIWalletTrackings.error) {
        // Catch error and set error message
        setError(PAIWalletTrackings.error);
      } else {
        setWalletTrackings(PAIWalletTrackings);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  const getPermission = () => {
    let
      result,
      message;

    if (!connectedWalletIsConnected || !connectedWalletAddress) {
      result = false;
      message = 'Please connect and sign your wallet to use this function.';
    } else if (!connectedWalletSignedData) {
      result = false;
      message = 'Please sign your wallet to use this function.';
    } else {
      result = true;
      message = 'You have permission to use this function';
    }

    return {
      result: result,
      message: message,
    }
  }

  const _getPermission = getPermission();

  useEffect(() => {
    if (_getPermission.result) {
      fetchWalletTrackings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWalletAddress, connectedWalletSignedData]);



  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,

      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    }}>
      <Container>

        <Grid container spacing={2} sx={{
          mt: 2,
        }}>

          <Grid item xs={12} md={3}>
            <Nav />

            <Card variant="outlined" sx={{
              mt: 2,
            }}>
              <CardContent sx={{
                display: 'flex',
              }}>
                <InfoIcon color="primary" fontSize="inherit" sx={{
                  mt: 0.2,
                  mr: 1,
                }} />
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Wallet Tracking?
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                p: 2,
              }}>
                <Typography variant="body2">
                  A Telegram notification can be sent to you when an address in your wallet tracking list buys/sells a token. The tracking address can also be useful for your wallet tracking.<br /><br />

                  Please connect to your Telegram account to get the notification.
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={9}>

            <Card variant="outlined" sx={{
              position: 'relative',
            }}>
              {/* Fetching */}
              {_getPermission.result && fetching &&
                <Box sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  background: '#ffffffcc',
                  zIndex: 1,
                }}>
                  <Box sx={{
                    width: '100%',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '40%',
                  }}>
                    <CircularProgress />
                  </Box>
                </Box>
              }
              {/* Fetching */}

              <WalletTrackingHeader
                fetchWalletTrackings={fetchWalletTrackings}
                _getPermission={_getPermission}
              />

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                {/* Guest */}
                {!_getPermission.result &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <Typography variant="body2">
                      {_getPermission.message}
                    </Typography>
                  </Box>
                }

                {/* Error */}
                {_getPermission.result && error &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                    color: 'secondary.main',
                  }}>
                    <Typography variant="body2">
                      {error.message}
                    </Typography>
                  </Box>
                }

                {/* Empty */}
                {_getPermission.result && !error && walletTrackings && walletTrackings.length === 0 &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <Typography variant="body2">
                      Not found any wallets.
                    </Typography>
                  </Box>
                }

                {/* List */}
                {_getPermission.result && !error && walletTrackings && walletTrackings.length > 0 &&
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Balance</TableCell>
                          <TableCell>Notification</TableCell>
                          <TableCell sx={{
                            whiteSpace: 'nowrap',
                          }}>Copy Buy</TableCell>
                          <TableCell sx={{
                            whiteSpace: 'nowrap',
                          }}>Copy Sell</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{
                        '& p': {
                          fontSize: 14,
                        },
                      }}>
                        {walletTrackings.map((walletTracking) => (
                          <WalletTrackingItem
                            key={walletTracking.id}
                            walletTracking={walletTracking}
                            fetchWalletTrackings={fetchWalletTrackings}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Box>

              <Divider />

              <WalletTrackingHeader
                sx={{
                  pb: '16px !important',
                }}
                setWalletTrackings={setWalletTrackings}
                fetchWalletTrackings={fetchWalletTrackings}
                _getPermission={_getPermission}
              />

            </Card>

          </Grid>

        </Grid>

      </Container>
    </Box>
  );
}
