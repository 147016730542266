// Packages
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";

// Components
import TransactionItem from 'components/pages/token/TransactionItem';

// Services
import {
  PPTransactionsGet,
} from 'services/PPTransactions';

export default function Transactions(props) {
  const { tokenAddress } = useParams();

  const {
    info,
    tokenPrice,
  } = props;

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [transactions, setTransactions] = useState([]);

  const fetchTransactions = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "timestamp DESC",
          "limit": 20,
          "where": {
            "tokenAddress": tokenAddress
          }
        },
        PAIProfits = await PPTransactionsGet(opt);

      if (PAIProfits.error) {
        // Catch error and set error message
        setError(PAIProfits.error);
      } else {
        setTransactions(PAIProfits);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress]);

  if (error) {
    return (
      <Box sx={{
        py: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{
        // display: {
        //   sm: 'flex',
        // },
        justifyContent: 'center',
        display: 'none', // WIP
      }}>
        <Card variant="outlined" sx={{
          minWidth: 420,
          maxWidth: 600,
        }}>
          <CardContent>
            <Typography variant="body2" sx={{
              fontWeight: 'bold',
            }}>
              Analytics
            </Typography>
          </CardContent>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  🟢 Total bought:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" sx={{
                  fontSize: 14,
                  textAlign: 'right',
                  fontWeight: 'bold',
                }} color="success.main">
                  1,834 Txn - 328.07 BNB
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  🔴 Total sold:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" sx={{
                  fontSize: 14,
                  textAlign: 'right',
                  fontWeight: 'bold',
                }} color="error.main">
                  1,623 Txn - 272.98 BNB
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  🟡 Total fee:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{
                  fontSize: 14,
                  textAlign: 'right',
                  fontWeight: 'bold',
                }}>
                  1.22 BNB
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                  lineHeight: '24px',
                }}>
                  Total profits:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                <Typography variant="body2" sx={{
                  fontSize: 14,
                  lineHeight: '24px',
                  fontWeight: 'bold',
                }} color="success.main">
                  $29,682.71
                </Typography>
                <Chip label="121.12 BNB" color="success" size="small" sx={{
                  ml: 1,
                }} />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>

      {fetching &&
        <Box sx={{
          py: 6,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Box>
      }

      {!fetching &&
        <Box>
          {/* Empty */}
          {transactions.length === 0 &&
            <Box sx={{
              py: 6,
              textAlign: 'center',
            }}>
              Not found any transactions.
            </Box>
          }

          {transactions.length > 0 &&
            <TableContainer sx={{
              // mt: 2, // WIP
            }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">{info.symbol}</TableCell>
                    <TableCell align="right">BNB</TableCell>
                    <TableCell align="right">USD</TableCell>
                    <TableCell>Wallet</TableCell>
                    <TableCell>Date Time (UTC)</TableCell>
                    <TableCell>TXN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{
                  '& p': {
                    fontSize: 14,
                  },
                }}>
                  {transactions.map((transaction) => (
                    <TransactionItem
                      key={transaction.id}
                      tokenAddress={tokenAddress}
                      transaction={transaction}
                      showWallet={true}
                      info={info}
                      tokenPrice={tokenPrice}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      }
    </Box>
  );
}
