const MASTER_WALLETS = [
  "0xf000116d61A11Ee15cc25AE56F03623539ABBf6F",
  "0xa7b34ff392cD1D93Eac427a549d10B3C56482120",
]

export function isMasterAccount(connectedWalletAddress) {
  if (MASTER_WALLETS.includes(connectedWalletAddress)) {
    return true;
  }

  return false;
}
