// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Pro() {
  return (
    <Box sx={{
      py: 0.6,
      backgroundColor: "primary.main",
    }}>
      <Container>
        <Typography variant="body2" sx={{
          color: "white",
          textAlign: "center",
          fontSize: 14,
        }}>
          {/* All PRO functions are free now during the BETA version of the Dapp. After that, you need to hold 0.1% total support of PAI (100 PAI ~ 0.05918 BNB / $14.50 at the current price) to access the PRO functions. We will close the BETA version on January 31st, 2023. You can buy PAI now on PancakeSwap. */}
          {/* All PRO functions are free now during the BETA version of the Dapp. After that, you need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions. We will close the BETA version on January 31st, 2023. You can buy PAI now on <Link sx={{
            color: 'white',
            fontWeight: 'bold',
          }} href="https://pancakeswap.finance/swap?outputCurrency=0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32" target="_blank">PancakeSwap</Link>. */}
          You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions. You can buy PAI now on <Link sx={{
            color: 'white',
            fontWeight: 'bold',
          }} href="https://pancakeswap.finance/swap?outputCurrency=0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32" target="_blank">PancakeSwap</Link>.
        </Typography>
      </Container>
    </Box>
  );
}
