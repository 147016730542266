// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Icons
import CachedIcon from '@mui/icons-material/Cached';
import InfoIcon from '@mui/icons-material/Info';

// Services
import {
  PaiBotWalletsGet,
} from 'services/PaiBotWallets';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';

// Library
import {
  isProAccount,
} from 'libraries/Pro';

// Components
import Nav from 'components/pages/functions/Nav';
import BotWalletItem from 'components/pages/functions/bot-wallet/BotWalletItem';



export default function BotWallet() {
  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [botWallets, setBotWallets] = useState(null);

  const fetchBotWallets = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        PaiBotWallets = await PaiBotWalletsGet(connectedWalletAddress, connectedWalletSignedData);

      if (PaiBotWallets.error) {
        // Catch error and set error message
        setError(PaiBotWallets.error);
      } else {
        setBotWallets(PaiBotWallets);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  const getPermission = () => {
    let
      result,
      message;

    if (!connectedWalletIsConnected || !connectedWalletAddress) {
      result = false;
      message = 'Please connect and sign your wallet to use this function.';
    } else if (!_isProAccount()) {
      result = false;
      message = 'You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions.';
    } else if (!connectedWalletSignedData) {
      result = false;
      message = 'Please sign your wallet to use this function.';
    } else {
      result = true;
      message = 'You have permission to use this function';
    }

    return {
      result: result,
      message: message,
    }
  }

  const _getPermission = getPermission();

  useEffect(() => {
    if (_getPermission.result) {
      fetchBotWallets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWalletAddress, connectedWalletSignedData]);



  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,

      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    }}>
      <Container>

        <Grid container spacing={2} sx={{
          mt: 2,
        }}>
          <Grid item xs={12} md={3}>
            <Nav />

            <Card variant="outlined" sx={{
              mt: 2,
            }}>
              <CardContent sx={{
                display: 'flex',
              }}>
                <InfoIcon color="primary" fontSize="inherit" sx={{
                  mt: 0.2,
                  mr: 1,
                }} />
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Bot Wallet?
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                p: 2,
              }}>
                <Typography variant="body2">
                  This is PRO function.<br /><br />

                  Bot Wallet is your assistant in buying/selling a token when you enable the copy trading function for a tracking wallet.<br /><br />

                  You can also use it to do a fast buy/sell a BSC token on the token page with only one click and it will be finished in 2 seconds.<br /><br />

                  Only you can access your bot wallet. Each person will connect to a different bot wallet.<br /><br />

                  1 wallet can only connect to 1 bot wallet at this time.
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={9}>

            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Bot Wallet
                </Typography>

                {_getPermission.result &&
                  <Button variant="outlined" size="small" startIcon={<CachedIcon />} sx={{
                    ml: 2,
                  }}
                    onClick={() => {
                      fetchBotWallets();
                    }}
                    disabled={!_getPermission.result}
                  >
                    Reload
                  </Button>
                }
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                {/* Guest */}
                {!_getPermission.result &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <Typography variant="body2">
                      {_getPermission.message}
                    </Typography>
                  </Box>
                }

                {/* First fetching */}
                {_getPermission.result && fetching && botWallets === null &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <CircularProgress />
                  </Box>
                }

                {/* Error */}
                {_getPermission.result && !fetching && error &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                    color: 'secondary.main',
                  }}>
                    <Typography variant="body2">
                      {error.message}
                    </Typography>
                  </Box>
                }

                {/* Empty */}
                {_getPermission.result && !fetching && !error && botWallets && botWallets.length === 0 &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <Typography variant="body2">
                      Not found any wallets.
                    </Typography>
                  </Box>
                }

                {/* List */}
                {_getPermission.result && !fetching && !error && botWallets && botWallets.length > 0 &&
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Balance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{
                        '& p': {
                          fontSize: 14,
                        },
                      }}>
                        {botWallets.map((botWallet) => (
                          <BotWalletItem
                            key={botWallet.id}
                            botWallet={botWallet}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Box>

            </Card>

          </Grid>
        </Grid>

      </Container>
    </Box>
  );
}
