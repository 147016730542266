// Packages
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

// Icons
import EditIcon from '@mui/icons-material/Edit';

// React Hook Form Mui
import {
  FormContainer,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';

// Store
import {
  selectAddress,
  selectSignedData,
} from 'app/connectedWalletSlice';

// Services
import {
  PaiWalletTrackingsPatch,
} from 'services/PaiWalletTrackings';



export default function Edit(props) {
  const {
    fetchWalletTrackings,
    walletTracking,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fetching, setFetching] = useState();

  const onSubmit = async data => {
    data.id = walletTracking.id;
    delete data['trackingWalletAddress'];

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const
        PAIWalletTracking = await PaiWalletTrackingsPatch(connectedWalletAddress, connectedWalletSignedData, data);

      if (PAIWalletTracking.error && PAIWalletTracking.error.message) {
        // Catch error and set error message
        console.error(PAIWalletTracking.error);
        enqueueSnackbar(PAIWalletTracking.error.message, { variant: 'warning' });
      } else if (PAIWalletTracking.success && PAIWalletTracking.success.message) {
        enqueueSnackbar(PAIWalletTracking.success.message);
        await fetchWalletTrackings();
      }

      // Turn off fetching
      setFetching(false);

      // Close the popup
      handleClose();
    }
  }



  return (
    <React.Fragment>
      <Button variant="outlined" size="small" startIcon={<EditIcon />} onClick={handleOpen}>
        Edit
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Edit wallet tracking address
        </DialogTitle>

        <FormContainer
          defaultValues={{
            trackingWalletAddress: walletTracking.trackingWalletAddress,
            description: walletTracking.description ? walletTracking.description : '',
            notification: walletTracking.notification ? true : false,
            copyBuyEnable: walletTracking.copyBuyEnable ? true : false,
            copyBuyAmount: walletTracking.copyBuyAmount ? walletTracking.copyBuyAmount : '',
            copySellEnable: walletTracking.copySellEnable ? true : false,
          }}
          onSuccess={onSubmit}
        >
          <DialogContent sx={{
            '& > :not(style)': {
              mt: 2,
            },
          }}>

            <TextFieldElement fullWidth size="small" name="trackingWalletAddress" label="Address" required disabled />
            <TextFieldElement fullWidth size="small" name="description" label="Description" autoFocus placeholder="Optional" />
            <SwitchElement name="notification" label="Notification" />

            <Divider />

            <SwitchElement name="copyBuyEnable" label="Copy buy enabled" />
            <TextFieldElement fullWidth size="small" name="copyBuyAmount" label="Buy amount" placeholder="0.01" />

            <Divider />

            <SwitchElement name="copySellEnable" label="Copy sell enabled" />

          </DialogContent>
          <DialogActions sx={{
            m: 2,
          }}>
            <Button onClick={handleClose} size="small">
              Cancel
            </Button>
            <Button variant="contained" size="small" type="submit" disabled={fetching}>
              Continue
            </Button>
          </DialogActions>
        </FormContainer>

      </Dialog>
    </React.Fragment>
  );
}
