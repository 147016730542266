// Pai Wallet Tracking GET
export async function PaiWalletTrackingsGet(walletAddress, signedData) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-wallet-trackings`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// Pai Wallet Tracking Post
export async function PaiWalletTrackingsPost(walletAddress, signedData, data) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-wallet-trackings/post`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
        data: data,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// Pai Wallet Tracking Patch
export async function PaiWalletTrackingsPatch(walletAddress, signedData, data) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-wallet-trackings/patch`;

  try {
    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
        data: data,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// Pai Wallet Tracking Delete
export async function PaiWalletTrackingsDelete(walletAddress, signedData, id) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-wallet-trackings/del`;

  try {
    const res = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
        id: id,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
