// Packages
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Components
import TransactionItem from 'components/pages/token/TransactionItem';
import WalletFilter from 'components/pages/token/WalletFilter';

// Library
import {
  formatToken,
} from 'libraries/Common';
import {
  isProAccount,
} from 'libraries/Pro';

// Services
import {
  PPTransactionsGet,
} from 'services/PPTransactions';
import {
  PAIProfitsGet,
} from 'services/PAIProfits';

// Store
import {
  selectPaiBalance,
} from 'app/paiSlice';
import {
  selectAddress,
  selectIsConnected,
} from 'app/connectedWalletSlice';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WalletTabs(props) {
  const { tokenAddress, wallet } = useParams();

  const {
    currentTab,
    info,
    tokenPrice,
  } = props;

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [errorProfit, setErrorProfit] = useState();
  const [fetchingProfit, setFetchingProfit] = useState();
  const [profit, setProfit] = useState(null);

  const fetchProfits = async () => {
    // Check PRO
    if (profit !== null && !_isProAccount()) {
      return false;
    }

    // Only fetch once a time
    if (!fetchingProfit) {
      // Reset error
      setErrorProfit();

      // Turn on fetching
      setFetchingProfit(true);

      const
        opt = {
          "where": {
            "walletToken": `${wallet.toLowerCase()}:${tokenAddress}`
          }
        },
        PAIProfits = await PAIProfitsGet(opt);

      if (PAIProfits.error) {
        // Catch error and set error message
        setErrorProfit(PAIProfits.error);
      } else if (PAIProfits.length > 0) {
        setProfit(PAIProfits[0]);
      } else if (PAIProfits.length === 0) {
        setProfit(false);
      }

      // Turn off fetching
      setFetchingProfit(false);
    }
  }



  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [transactions, setTransactions] = useState(null);

  const fetchTransactions = async () => {
    // Check PRO
    if (transactions !== null && !_isProAccount()) {
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        opt = {
          "order": "timestamp DESC",
          "limit": 20,
          "where": {
            "walletToken": `${wallet.toLowerCase()}:${tokenAddress}`
          }
        },
        PPTransactions = await PPTransactionsGet(opt);

      if (PPTransactions.error) {
        // Catch error and set error message
        setError(PPTransactions.error);
      } else {
        setTransactions(PPTransactions);
      }

      // Turn off fetching
      setFetching(false);
    }
  }



  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(Math.random());
    }, 1000 * 10);

    return (() => {
      clearInterval(intervalId);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProfits();
    fetchTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, wallet]);

  if (error) {
    return (
      <Box sx={{
        py: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  return (
    <Card variant="outlined" sx={{
      mt: 1,
      position: 'relative',
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} sx={{
          '& button': {
            textTransform: 'none',
          },
        }}>
          <Tab label="Transactions" {...a11yProps(0)} />

          {!_isProAccount() &&
            <Tooltip
              title="Real-time with PRO wallet"
              placement="top"
              arrow
            >
              <Box component="span" className="living inactive" sx={{
                ml: 2,
                mt: 2.5,
              }}></Box>
            </Tooltip>
          }
          {_isProAccount() &&
            <Tooltip
              title="Real-time"
              placement="top"
              arrow
            >
              <Box component="span" className="living" sx={{
                ml: 2,
                mt: 2.5,
              }}></Box>
            </Tooltip>
          }
        </Tabs>
      </Box>

      <WalletFilter currentTab={currentTab} />

      <TabPanel value={value} index={0}>
        <Box sx={{
          display: {
            sm: 'flex',
          },
          justifyContent: 'center',
        }}>
          {fetchingProfit && profit === null &&
            <Box sx={{
              py: 6,
              textAlign: 'center',
            }}>
              <CircularProgress />
            </Box>
          }

          {profit !== null &&
            <Box>
              {/* Error */}
              {errorProfit &&
                <Box sx={{
                  py: 6,
                  textAlign: 'center',
                  color: 'secondary.main',
                }}>
                  {errorProfit.message}
                </Box>
              }

              {!errorProfit && !profit &&
                <Box sx={{
                  py: 6,
                  textAlign: 'center',
                }}>
                  Not found any profit.
                </Box>
              }

              {!errorProfit && profit &&
                <Card variant="outlined" sx={{
                  minWidth: 420,
                  maxWidth: 600,
                }}>
                  <CardContent>
                    <Typography variant="body2" sx={{
                      fontWeight: 'bold',
                    }}>
                      Analytics from the last sold
                    </Typography>
                  </CardContent>

                  <Divider />

                  <Box sx={{
                    py: 1,
                    px: 2,
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{
                          color: "#12161c",
                          fontSize: 14,
                        }}>
                          🟢 Total bought:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{
                          fontSize: 14,
                          textAlign: 'right',
                          fontWeight: 'bold',
                        }} color="success.main">
                          {profit.totalBoughtTxn} Txn - {formatToken(profit.totalBoughtAmount, 3, 3)} BNB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box sx={{
                    py: 1,
                    px: 2,
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{
                          color: "#12161c",
                          fontSize: 14,
                        }}>
                          🔴 Total sold:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{
                          fontSize: 14,
                          textAlign: 'right',
                          fontWeight: 'bold',
                        }} color="error.main">
                          {profit.totalSoldTxn} Txn - {formatToken(profit.totalSoldAmount, 3, 3)} BNB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box sx={{
                    py: 1,
                    px: 2,
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{
                          color: "#12161c",
                          fontSize: 14,
                        }}>
                          🟡 Total fee:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{
                          fontSize: 14,
                          textAlign: 'right',
                          fontWeight: 'bold',
                        }}>
                          {profit.totalFeeTxn} Txn - {formatToken(profit.totalFeeAmount, 3, 3)} BNB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box sx={{
                    py: 1,
                    px: 2,
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{
                          color: "#12161c",
                          fontSize: 14,
                          lineHeight: '24px',
                        }}>
                          Total profit:
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}>
                        <Typography variant="body2" sx={{
                          fontSize: 14,
                          lineHeight: '24px',
                          fontWeight: 'bold',
                          display: 'none', // WIP
                        }} color="success.main">
                          $842.61
                        </Typography>
                        <Chip label={`${formatToken(profit.realProfit, 3, 3)} BNB`} color="success" size="small" sx={{
                          // ml: 1, // WIP
                        }} />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              }
            </Box>
          }
        </Box>

        {/* First fetching */}
        {fetching && transactions === null &&
          <Box sx={{
            py: 6,
            textAlign: 'center',
          }}>
            <CircularProgress />
          </Box>
        }

        {transactions !== null &&
          <Box>
            {/* Empty */}
            {transactions.length === 0 &&
              <Box sx={{
                py: 6,
                textAlign: 'center',
              }}>
                Not found any transactions.
              </Box>
            }

            {transactions.length > 0 &&
              <TableContainer sx={{
                mt: 2,
              }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">{info.symbol}</TableCell>
                      <TableCell align="right">BNB</TableCell>
                      <TableCell align="right">USD</TableCell>
                      <TableCell align="right">Current value</TableCell>
                      <TableCell>Date Time (UTC)</TableCell>
                      <TableCell>TXN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{
                    '& p': {
                      fontSize: 14,
                    },
                  }}>
                    {transactions.map((transaction) => (
                      <TransactionItem
                        key={transaction.id}
                        tokenAddress={tokenAddress}
                        transaction={transaction}
                        showWallet={false}
                        showCurrentValue={true}
                        info={info}
                        tokenPrice={tokenPrice}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Box>
        }
      </TabPanel>
    </Card>
  );
}
