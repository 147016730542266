// Packages
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// Material UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// React Hook Form Mui
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';

// Icons
import SearchIcon from '@mui/icons-material/Search';

// Library
import {
  isBscTokenAddress,
} from 'libraries/Web3';

export default function Search() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async data => {
    if (data && data.q) {
      const address = await isBscTokenAddress(data.q);
      if (address) {
        navigate(`/token/${address}`);
      } else {
        enqueueSnackbar('Token not found', { variant: 'warning' });
      }
    }
  }

  return (
    <Box sx={{
      py: 1.5,
      background: '#000616',
    }}>
      <Container>
        <Typography variant="body2" sx={{
          color: "white",
          fontSize: 18,
          fontWeight: 400,
          textAlign: 'center',
        }}>
          Track All Profits Easier Than Ever
        </Typography>

        <Paper variant="outlined" sx={{
          mt: 2,
          maxWidth: {
            md: '60%',
          },
          display: 'none',
        }}>
          <FormContainer onSuccess={onSubmit}>
            <Box sx={{
              display: 'flex',
            }}>
              <TextFieldElement
                sx={{
                  flex: 1,
                  "& fieldset": { border: 'none' },
                  "& label.Mui-focused, & label.MuiFormLabel-filled": {
                    display: 'none',
                  },
                }}
                label={'Search by Token'}
                name={'q'}
              />
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
          </FormContainer>
        </Paper>
      </Container>
    </Box>
  );
}
