// Packages
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useState } from 'react';


// Material UI
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Services
import {
  PAIMemeContestsVote,
} from 'services/PAIMemeContests';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';



const VOTE_ENABLE = false;



function RenderFavoriteIcon(props) {
  const {
    fetching,
    isVoted,
  } = props;

  if (fetching) {
    return <RefreshIcon
      sx={{
        animation: "spin 2s linear infinite",
        "@keyframes spin": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "000%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    />;
  } else if (!isVoted) {
    return <ThumbUpIcon />;
  } else {
    return <ThumbUpIcon color="primary" />;
  }
}



export default function MemeContestItem(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    fetchMemeContests,
    memeContest,
    myTotalVotes,
  } = props;

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const paiBalance = useSelector(selectPaiBalance);



  const isVoted = function () {
    // No votes yet
    if (!memeContest.paiMemeContestVotes) {
      return false;
    }

    // Did not logged in
    if (!connectedWalletIsConnected && !connectedWalletAddress) {
      return false;
    }

    const find = memeContest.paiMemeContestVotes.find(x => x.walletAddress === connectedWalletAddress);

    if (find) {
      return true;
    } else {
      return false;
    }
  }



  const [fetching, setFetching] = useState();

  const vote = async (id) => {
    if (!connectedWalletIsConnected || !connectedWalletAddress) {
      enqueueSnackbar('Please connect and sign your wallet to vote!', { variant: 'warning' });
      return false;
    }

    if (paiBalance < 10) {
      enqueueSnackbar('You need to hold at least 10 PAI to vote!', { variant: 'warning' });
      return false;
    }

    if (!connectedWalletSignedData) {
      enqueueSnackbar('Please sign your wallet to vote!', { variant: 'warning' });
      return false;
    }

    // Limit max 3 votes per wallet
    if (!isVoted() && myTotalVotes >= 3) {
      enqueueSnackbar('Max 3 votes per wallet!', { variant: 'warning' });
      return false;
    }



    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const
        PAIMemeContest = await PAIMemeContestsVote(id, connectedWalletAddress, connectedWalletSignedData);

      if (PAIMemeContest.error && PAIMemeContest.error.message) {
        // Catch error and set error message
        console.error(PAIMemeContest.error);
        enqueueSnackbar(PAIMemeContest.error.message, { variant: 'warning' });
      } else {
        enqueueSnackbar(`You voted ${PAIMemeContest}/3 entries`);
        await fetchMemeContests();
      }

      // Turn off fetching
      setFetching(false);
    }
  }



  return (
    <Grid key={memeContest.id} item xs={12} sm={6}>
      <Card variant="outlined">
        <CardContent>
          <TwitterTweetEmbed
            tweetId={memeContest.tweetId}
            options={{ conversation: 'none' }}
          />
        </CardContent>
        <CardActions>
          <Typography variant="body2" sx={{
            fontWeight: 'bold',
            ml: 1,
          }}>
            PAI holders votes:
          </Typography>

          <IconButton disabled={VOTE_ENABLE ? fetching : true} sx={{
            ml: 1,
          }} onClick={() => {
            vote(memeContest.id);
          }}>
            <RenderFavoriteIcon
              fetching={fetching}
              isVoted={isVoted()}
            />
          </IconButton>

          <Typography variant="body2" sx={{
            ml: 1,
          }}>
            {(memeContest.paiMemeContestVotes && memeContest.paiMemeContestVotes.length) || 0}
          </Typography>
        </CardActions>
      </Card>
    </Grid>
  );
}
