// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

// Store
import {
  selectAddress,
  selectIsConnected,
  selectSignedData,
} from 'app/connectedWalletSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';

// Library
import {
  isProAccount,
} from 'libraries/Pro';

// Services
import {
  PaiTelegramConnectionsGet,
  PaiTelegramConnectionsDisconnect,
} from 'services/PaiTelegramConnections';

// Components
import Nav from 'components/pages/functions/Nav';



export default function TelegramConnection() {
  const { enqueueSnackbar } = useSnackbar();

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }

  const getPermission = () => {
    let
      result,
      message;

    if (!connectedWalletIsConnected || !connectedWalletAddress) {
      result = false;
      message = 'Please connect and sign your wallet to use this function.';
    } else if (!_isProAccount()) {
      result = false;
      message = 'You need to hold 0.1% total support of PAI (100 PAI) to access the PRO functions.';
    } else if (!connectedWalletSignedData) {
      result = false;
      message = 'Please sign your wallet to use this function.';
    } else {
      result = true;
      message = 'You have permission to use this function';
    }

    return {
      result: result,
      message: message,
    }
  }

  const _getPermission = getPermission();



  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [telegramConnection, setTelegramConnection] = useState(null);

  const fetchTelegramConnection = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const
        PAITelegramConnection = await PaiTelegramConnectionsGet(connectedWalletAddress, connectedWalletSignedData);

      if (PAITelegramConnection.error) {
        // Catch error and set error message
        setError(PAITelegramConnection.error);
      } else {
        setTelegramConnection(PAITelegramConnection[0]);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(Math.random());
    }, 1000 * 10);

    return (() => {
      clearInterval(intervalId);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_getPermission.result) {
      fetchTelegramConnection();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, connectedWalletAddress, connectedWalletSignedData]);

  const [fetchingDisconnect, setFetchingDisconnect] = useState();

  const disconnect = async () => {
    // Only fetch once a time
    if (!fetchingDisconnect) {
      // Turn on fetching
      setFetchingDisconnect(true);

      const
        PAITelegramConnection = await PaiTelegramConnectionsDisconnect(connectedWalletAddress, connectedWalletSignedData);

      if (PAITelegramConnection.error && PAITelegramConnection.error.message) {
        // Catch error and set error message
        console.error(PAITelegramConnection.error);
        enqueueSnackbar(PAITelegramConnection.error.message, { variant: 'warning' });
      } else {
        enqueueSnackbar(`You have already disconnected your Telegram account`);
        await fetchTelegramConnection();
      }

      // Turn off fetching
      setFetchingDisconnect(false);
    }
  }

  return (
    <Box component="main" sx={{
      backgroundColor: '#f8f9fa',
      pb: 6,

      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    }}>
      <Container>

        <Grid container spacing={2} sx={{
          mt: 2,
        }}>

          <Grid item xs={12} md={3}>
            <Nav />

            <Card variant="outlined" sx={{
              mt: 2,
            }}>
              <CardContent sx={{
                display: 'flex',
              }}>
                <InfoIcon color="primary" fontSize="inherit" sx={{
                  mt: 0.2,
                  mr: 1,
                }} />
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Telegram Connection?
                </Typography>
              </CardContent>

              <Divider />

              <Box sx={{
                p: 2,
              }}>
                <Typography variant="body2">
                  PRO users can connect to their Telegram account with only 1 step to get notifications about wallet tracking, copy trading, and bot wallet trading information.<br /><br />

                  1 wallet can only connect to 1 Telegram account.<br /><br />

                  ➔ Click the <strong>Connect</strong> button on the Dapp, then click the <strong>Start</strong> button on your Telegram
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={9}>

            <Card variant="outlined">
              <CardContent>
                <Typography variant="body2" sx={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}>
                  Telegram Connection
                </Typography>

                {/* Connect */}
                {_getPermission.result && !error && telegramConnection && !telegramConnection.tgId &&
                  <Button variant="contained" size="small" startIcon={<SettingsEthernetIcon />} href={`https://t.me/${process.env.REACT_APP_PROFIT_AI_BOT_USERNAME}?start=${telegramConnection.key}`} target="_blank" sx={{
                    ml: 1
                  }}>
                    Connect
                  </Button>
                }

                {/* Disconnect */}
                {_getPermission.result && !error && telegramConnection && telegramConnection.tgId &&
                  <Button variant="contained" color="error" size="small" startIcon={<SettingsEthernetIcon />} sx={{
                    ml: 1
                  }}
                    onClick={disconnect}
                    disabled={fetchingDisconnect}
                  >
                    Disconnect
                  </Button>
                }
              </CardContent>

              <Divider />

              <Box sx={{
                py: 1,
                px: 2,
              }}>
                {/* Guest */}
                {!_getPermission.result &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <Typography variant="body2">
                      {_getPermission.message}
                    </Typography>
                  </Box>
                }

                {/* First fetching */}
                {_getPermission.result && fetching && telegramConnection === null &&
                  <Box sx={{
                    py: 6,
                    textAlign: 'center',
                  }}>
                    <CircularProgress />
                  </Box>
                }

                {telegramConnection !== null &&
                  <React.Fragment>
                    {/* Error */}
                    {_getPermission.result && error &&
                      <Box sx={{
                        py: 6,
                        textAlign: 'center',
                        color: 'secondary.main',
                      }}>
                        <Typography variant="body2">
                          {error.message}
                        </Typography>
                      </Box>
                    }

                    {/* Unknown error */}
                    {_getPermission.result && !error && !telegramConnection &&
                      <Box sx={{
                        py: 6,
                        textAlign: 'center',
                      }}>
                        <Typography variant="body2">
                          Unknown error when preparing your telegram connection
                        </Typography>
                      </Box>
                    }

                    {_getPermission.result && !error && telegramConnection &&
                      <React.Fragment>

                        <Box sx={{
                          py: 1,
                          px: 2,
                        }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Typography variant="body2" sx={{
                                color: "#12161c",
                                fontSize: 14,
                                lineHeight: '24px',
                              }}>
                                Status:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} className="xs-no-pt">
                              {!telegramConnection.tgId &&
                                <Chip label="Disconnected" size="small" />
                              }
                              {telegramConnection.tgId &&
                                <Chip label="Connected" color="success" size="small" />
                              }
                            </Grid>
                          </Grid>
                        </Box>

                        {telegramConnection.tgId &&
                          <React.Fragment>
                            <Divider />

                            <Box sx={{
                              py: 1,
                              px: 2,
                            }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    Telegram ID:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className="xs-no-pt">
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    {telegramConnection.tgId}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            <Divider />

                            <Box sx={{
                              py: 1,
                              px: 2,
                            }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    Telegram First Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className="xs-no-pt">
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    {telegramConnection.tgFirstName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            <Divider />

                            <Box sx={{
                              py: 1,
                              px: 2,
                            }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    Telegram Last Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className="xs-no-pt">
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    {telegramConnection.tgLastName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            <Divider />

                            <Box sx={{
                              py: 1,
                              px: 2,
                            }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    Telegram Username:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className="xs-no-pt">
                                  <Typography variant="body2" sx={{
                                    color: "#12161c",
                                    fontSize: 14,
                                  }}>
                                    <Link href={`https://t.me/${telegramConnection.tgUsername}`} target="_blank">@{telegramConnection.tgUsername}</Link>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </React.Fragment>
                        }

                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </Box>

            </Card>

          </Grid>

        </Grid>

      </Container>
    </Box>
  );
}
