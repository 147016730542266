// Packages
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Library
import {
  formatToken,
} from 'libraries/Common';
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectPaiBalance,
} from 'app/paiSlice';
import {
  selectAddress,
  selectIsConnected,
} from 'app/connectedWalletSlice';



export default function TradingInfo(props) {
  const {
    info,
    tokenAnalyse,
    tokenPrice,
  } = props;



  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }



  const
    maxTXAmount = tokenAnalyse.maxTXAmount,
    totalSupply = tokenAnalyse.totalSupply,
    buyGasUsed = tokenAnalyse.buyGasUsed,
    sellGasUsed = tokenAnalyse.sellGasUsed,
    buyTax = tokenAnalyse.buyTax,
    sellTax = tokenAnalyse.sellTax;

  const percentTotalSupply = maxTXAmount / totalSupply * 100;

  const renderBuyTax = () => {
    if (buyTax === undefined) {
      return 'Undefined';
    }

    const format = formatToken(buyTax, 1, 1);

    if (!format) {
      return '0%'
    }

    return `${format}%`;
  }

  const renderSellTax = () => {
    if (sellTax === undefined) {
      return 'Undefined';
    }

    const format = formatToken(sellTax, 1, 1);

    if (!format) {
      return '0%'
    }

    return `${format}%`;
  }



  return (
    <Grid container spacing={2} sx={{
      mt: 0,

      '& .xs-no-pt': {
        pt: {
          xs: '0 !important',
          sm: '16px !important',
        },
      },
    }}>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                  lineHeight: {
                    md: '28px',
                  },
                }}>
                  Buy Gas:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} className="xs-no-pt">
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                  lineHeight: {
                    md: '28px',
                  },
                }}>
                  <Box component="span">
                    {isNaN(buyGasUsed) && NaN}
                    {!isNaN(buyGasUsed) && formatToken(buyGasUsed, 0, 0)}
                  </Box>
                  {!_isProAccount() &&
                    <Tooltip
                      title="Real-time with PRO wallet"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living inactive"></Box>
                    </Tooltip>
                  }
                  {_isProAccount() &&
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                  lineHeight: {
                    md: '28px',
                  },
                }}>
                  Sell Gas:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} className="xs-no-pt">
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                  lineHeight: {
                    md: '28px',
                  },
                }}>
                  <Box component="span">
                    {isNaN(sellGasUsed) && NaN}
                    {!isNaN(sellGasUsed) && formatToken(sellGasUsed, 0, 0)}
                  </Box>
                  {!_isProAccount() &&
                    <Tooltip
                      title="Real-time with PRO wallet"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living inactive"></Box>
                    </Tooltip>
                  }
                  {_isProAccount() &&
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  Max TX:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} className="xs-no-pt">
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  <Box component="span">
                    {formatToken(maxTXAmount, 0)} <strong>{info.symbol}</strong>
                  </Box>
                  {!_isProAccount() &&
                    <Tooltip
                      title="Real-time with PRO wallet"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living inactive"></Box>
                    </Tooltip>
                  }
                  {_isProAccount() &&
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  }
                </Typography>
                <Typography variant="body2" sx={{
                  color: "#77838f",
                  fontSize: 11,
                }}>
                  {percentTotalSupply === 100 &&
                    <React.Fragment>
                      ({formatToken(percentTotalSupply, 2, 2)}% total supply)
                    </React.Fragment>
                  }
                  {percentTotalSupply !== 100 &&
                    <React.Fragment>
                      ({formatToken(percentTotalSupply, 2, 2)}% total supply - {formatToken(maxTXAmount * tokenPrice, 3, 3)} BNB)
                    </React.Fragment>
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{
            py: 1,
            px: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  Taxes (buy/sell):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} className="xs-no-pt">
                <Typography variant="body2" sx={{
                  color: "#12161c",
                  fontSize: 14,
                }}>
                  <Box component="span">
                    {renderBuyTax()} / {renderSellTax()}
                  </Box>
                  {!_isProAccount() &&
                    <Tooltip
                      title="Real-time with PRO wallet"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living inactive"></Box>
                    </Tooltip>
                  }
                  {_isProAccount() &&
                    <Tooltip
                      title="Real-time"
                      placement="top"
                      arrow
                    >
                      <Box component="span" className="living"></Box>
                    </Tooltip>
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
