// Packages
import {
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";

// Material UI
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TelegramIcon from '@mui/icons-material/Telegram';

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}



export default function Nav() {
  const navigate = useNavigate();

  const routeMatch = useRouteMatch([
    '/functions/wallet-tracking',
    '/functions/telegram-connection',
    '/functions/bot-wallet',
  ]);
  const currentPath = routeMatch?.pattern?.path;

  return (
    <Card variant="outlined">
      <List dense sx={{
        py: 0,

        '& .MuiListItemIcon-root': {
          minWidth: '26px',
        },

        '& li.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'white',

          '& .MuiListItemIcon-root': {
            color: 'white',
          },
        },
      }}>
        <ListItem
          disablePadding
          selected={currentPath === '/functions/wallet-tracking'}
          onClick={() => {
            navigate(`/functions/wallet-tracking`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <FavoriteIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Wallet Tracking" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem
          disablePadding
          selected={currentPath === '/functions/telegram-connection'}
          onClick={() => {
            navigate(`/functions/telegram-connection`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <TelegramIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Telegram Connection" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem
          disablePadding
          selected={currentPath === '/functions/bot-wallet'}
          onClick={() => {
            navigate(`/functions/bot-wallet`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SmartToyIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Bot Wallet" />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
}
