// Packages
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";

// Icon
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// Components
import WalletSwap from 'components/pages/token/WalletSwap';

// Library
import {
  getBalance,
  getBnbBalance,
} from 'libraries/Web3';
import {
  formatToken,
  formatTokenDynamic,
} from 'libraries/Common';
import {
  isProAccount,
} from 'libraries/Pro';

// Store
import {
  selectIsConnected,
  selectAddress,
} from 'app/connectedWalletSlice';
import {
  selectBnbPrice,
} from 'app/bnbSlice';
import {
  selectPaiBalance,
} from 'app/paiSlice';

// Images
import bscscan from 'images/bscscan_40x40.png';



// custom hook for getting previous value
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}



function FilterBscscan(props) {
  const navigate = useNavigate();

  const {
    tokenAddress,
  } = useParams();

  const {
    wallet,
    filter,
  } = props;

  return (
    <React.Fragment>
      {!filter &&
        <Tooltip title="Filter your wallet" placement="top" arrow sx={{
          position: {
            md: 'absolute',
          },
          bottom: {
            md: 0,
          },
          right: {
            md: 40,
          },

          mt: {
            xs: 1,
            md: 0,
          },

          width: 36,
          height: 36,
          // transition: "all .3s ease",

          '& img': {
            height: '100%',
          },
        }}>
          <IconButton target="_blank" onClick={() => {
            navigate(`/token/${tokenAddress}/transactions/${wallet}`);
          }}>
            <FilterAltIcon />
          </IconButton>
        </Tooltip>
      }

      <Tooltip title="Check on bscscan" placement="top" arrow sx={{
        position: {
          md: 'absolute',
        },
        bottom: {
          md: 0,
        },
        right: {
          md: 0,
        },

        mt: {
          xs: 1,
          md: 0,
        },

        width: 36,
        height: 36,
        // transition: "all .3s ease",

        '& img': {
          height: '100%',
        },
      }}>
        <IconButton target="_blank" href={`https://bscscan.com/token/${tokenAddress}?a=${wallet}`}>
          <CardMedia
            component="img"
            src={bscscan}
            alt="Bscscan"
          />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}



export default function WalletTrackerItem(props) {
  const {
    tokenAddress,
  } = useParams();

  const prevTokenAddress = usePrevious(tokenAddress);

  const connectedWalletIsConnected = useSelector(selectIsConnected);
  const connectedWalletAddress = useSelector(selectAddress);

  const paiBalance = useSelector(selectPaiBalance);

  const _isProAccount = () => {
    return isProAccount(connectedWalletIsConnected, connectedWalletAddress, paiBalance);
  }

  const bnbPrice = useSelector(selectBnbPrice);

  const {
    info,
    tokenAnalyse,
    tokenPrice,
    wallet,
    name,
    bot,
    filter,
  } = props;

  const trackerWallet = wallet ? wallet : connectedWalletAddress;

  const prevTrackerWallet = usePrevious(trackerWallet);

  const
    maxTXAmount = tokenAnalyse.maxTXAmount,
    sellTax = tokenAnalyse.sellTax;

  const [fetching, setFetching] = useState();
  const [balance, setBalance] = useState(false);
  const [bnbBalance, setBnbBalance] = useState(false);

  // declare the data fetching function
  const fetchData = async () => {
    if (!trackerWallet) {
      return false;
    }

    // Check PRO
    if (balance !== false && !_isProAccount()) {
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      if (
        prevTokenAddress !== tokenAddress
        || prevTrackerWallet !== trackerWallet
      ) {
        setBalance(false);
        setBnbBalance(false);
      }

      try {
        // Get Bnb Balance
        const _bnbBalance = await getBnbBalance(trackerWallet);
        setBnbBalance(_bnbBalance);

        // Get token balance
        let _balance;
        if (tokenAddress === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
          // BNB
          _balance = _bnbBalance;
        } else {
          _balance = await getBalance(tokenAddress, trackerWallet);
        }
        setBalance(_balance);
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetching(false);
    }
  }



  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(Math.random());
    }, 1000 * 5);

    return (() => {
      clearInterval(intervalId);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, tokenAddress, trackerWallet]);

  if (!connectedWalletIsConnected || !trackerWallet) {
    return (
      <Paper variant="outlined" sx={{
        mt: 2,
        p: 2,
        textAlign: 'center',
      }}>
        <Typography variant="body2">
          Please connect wallet to see your balance
        </Typography>
      </Paper>
    );
  }

  const percentTotalSupply = balance / info.totalSupply * 100;
  const percentRemaining = (maxTXAmount - balance) / info.totalSupply * 100;

  let
    renderSellTax = formatToken(sellTax, 1, 1);

  if (renderSellTax) {
    renderSellTax = `${renderSellTax}%`;
  } else {
    renderSellTax = '0%';
  }

  const noMax = formatToken(maxTXAmount / info.totalSupply * 100, 0, 0) === '100' ? true : false;

  return (
    <Paper variant="outlined" sx={{
      mt: 2,
      p: 2,
      backgroundColor: trackerWallet === connectedWalletAddress ? blue[100] : 'transparent',
    }}>
      <Grid container spacing={2} sx={{
        position: 'relative',
      }}>
        <Grid item xs={12} md={noMax ? 6 : 4}>
          <Typography variant="body2" sx={{
            color: "#77838f",
            fontSize: 11,
            fontWeight: 'bold',
          }}>
            {name.toUpperCase()}
          </Typography>
          <Typography variant="body2" className="text-truncate" sx={{
            color: "#12161c",
            fontSize: 14,
          }}>
            {trackerWallet}
          </Typography>

          <Typography variant="body2" sx={{
            color: "#77838f",
            fontSize: 11,
            mt: 2,
          }}>
            BNB BALANCE
          </Typography>
          <Typography variant="body2" sx={{
            color: "#12161c",
            fontSize: 14,

            '& span.bnb': {
              color: "#77838f",
              fontSize: 11,
              ml: 0.5,
            },
          }}>
            {bnbBalance === 0 &&
              <Box component="span">
                0.0000 BNB
              </Box>
            }
            {bnbBalance !== false && bnbBalance !== 0 &&
              <React.Fragment>
                <Box component="span">
                  {formatToken(bnbBalance, 4, 4)} BNB
                </Box>
                <Box component="span" className="bnb">
                  (${formatToken(bnbBalance * bnbPrice, 2, 2)})
                </Box>
              </React.Fragment>
            }

            {!_isProAccount() &&
              <Tooltip
                title="Real-time with PRO wallet"
                placement="top"
                arrow
              >
                <Box component="span" className="living inactive"></Box>
              </Tooltip>
            }
            {_isProAccount() &&
              <Tooltip
                title="Real-time"
                placement="top"
                arrow
              >
                <Box component="span" className="living"></Box>
              </Tooltip>
            }
          </Typography>
        </Grid>
        <Grid item xs={12} md={noMax ? 6 : 4}>
          <Typography variant="body2" sx={{
            color: "#77838f",
            fontSize: 11,
          }}>
            BALANCE
          </Typography>
          <Typography variant="body2" sx={{
            color: "#12161c",
            fontSize: 14,

            '& span.bnb': {
              color: "#77838f",
              fontSize: 11,
              ml: 0.5,
            },
          }}>
            {balance === 0 &&
              <Box component="span">
                0 <strong>{info.symbol}</strong>
              </Box>
            }
            {balance !== false && balance !== 0 &&
              <React.Fragment>
                <Box component="span">
                  {formatTokenDynamic(balance, 1)} <strong>{info.symbol}</strong>
                </Box>
                <Box component="span" className="bnb">
                  ({formatToken(percentTotalSupply, 3, 3)}% total supply)
                </Box>
              </React.Fragment>
            }

            {!_isProAccount() &&
              <Tooltip
                title="Real-time with PRO wallet"
                placement="top"
                arrow
              >
                <Box component="span" className="living inactive"></Box>
              </Tooltip>
            }
            {_isProAccount() &&
              <Tooltip
                title="Real-time"
                placement="top"
                arrow
              >
                <Box component="span" className="living"></Box>
              </Tooltip>
            }

          </Typography>

          <Typography variant="body2" sx={{
            color: "#77838f",
            fontSize: 11,
            mt: 2,
          }}>
            VALUE / VALUE WITH SELL TAX ({renderSellTax})
          </Typography>
          <Typography variant="body2" sx={{
            color: "#12161c",
            fontSize: 14,

            '& span.bnb': {
              color: "#77838f",
              fontSize: 11,
              ml: 0.5,
            },
          }}>

            {sellTax !== 0 &&
              <React.Fragment>
                {balance === 0 &&
                  <Box component="span">
                    0.0000 BNB
                  </Box>
                }
                {balance !== false && balance !== 0 &&
                  <React.Fragment>
                    <Box component="span">
                      {formatToken(balance * tokenPrice, 4, 4)} BNB
                    </Box>
                    <Box component="span" className="bnb">
                      (${formatToken(balance * tokenPrice * bnbPrice, 2, 2)})
                    </Box>
                  </React.Fragment>
                }
                {` / `}
              </React.Fragment>
            }

            {balance === 0 &&
              <Box component="span" color="success.main" sx={{
                fontWeight: "bold",
              }}>
                0.0000 BNB
              </Box>
            }
            {balance !== false && balance !== 0 &&
              <React.Fragment>
                <Box component="span" color="success.main" sx={{
                  fontWeight: "bold",
                }}>
                  {formatToken(balance * tokenPrice * (1 - sellTax / 100), 4, 4)} BNB
                </Box>
                <Box component="span" className="bnb" sx={{
                  fontWeight: "bold",
                }}>
                  (${formatToken(balance * tokenPrice * bnbPrice * (1 - sellTax / 100), 2, 2)})
                </Box>
              </React.Fragment>
            }

            {!_isProAccount() &&
              <Tooltip
                title="Real-time with PRO wallet"
                placement="top"
                arrow
              >
                <Box component="span" className="living inactive"></Box>
              </Tooltip>
            }
            {_isProAccount() &&
              <Tooltip
                title="Real-time"
                placement="top"
                arrow
              >
                <Box component="span" className="living"></Box>
              </Tooltip>
            }

          </Typography>

          {noMax &&
            <FilterBscscan
              wallet={trackerWallet}
              filter={filter}
            />
          }
        </Grid>

        {!noMax &&
          <Grid item xs={12} md={noMax ? 6 : 4}>
            <Typography variant="body2" sx={{
              color: "#77838f",
              fontSize: 11,
            }}>
              CAN BUY MORE
            </Typography>

            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,

              '& span.bnb': {
                color: "#77838f",
                fontSize: 11,
                ml: 0.5,
              },
            }}>
              <Box component="span">
                {formatToken(maxTXAmount - balance, 3, 3)} <strong>{info.symbol}</strong>
              </Box>
              <Box component="span" className="bnb">
                ({formatToken(percentRemaining, 3, 3)}% total supply)
              </Box>

              {!_isProAccount() &&
                <Tooltip
                  title="Real-time with PRO wallet"
                  placement="top"
                  arrow
                >
                  <Box component="span" className="living inactive"></Box>
                </Tooltip>
              }
              {_isProAccount() &&
                <Tooltip
                  title="Real-time"
                  placement="top"
                  arrow
                >
                  <Box component="span" className="living"></Box>
                </Tooltip>
              }

            </Typography>

            <Typography variant="body2" sx={{
              color: "#77838f",
              fontSize: 11,
              mt: 2,
            }}>
              VALUE
            </Typography>

            <Typography variant="body2" sx={{
              color: "#12161c",
              fontSize: 14,

              '& span.bnb': {
                color: "#77838f",
                fontSize: 11,
                ml: 0.5,
              },
            }}>
              {balance !== false &&
                <React.Fragment>
                  <Box component="span">
                    {formatToken((maxTXAmount - balance) * tokenPrice * (1 - sellTax / 100), 6, 6)} BNB
                  </Box>
                  <Box component="span" className="bnb">
                    (${formatToken((maxTXAmount - balance) * tokenPrice * bnbPrice * (1 - sellTax / 100), 2, 2)})
                  </Box>
                </React.Fragment>
              }

              {!_isProAccount() &&
                <Tooltip
                  title="Real-time with PRO wallet"
                  placement="top"
                  arrow
                >
                  <Box component="span" className="living inactive"></Box>
                </Tooltip>
              }
              {_isProAccount() &&
                <Tooltip
                  title="Real-time"
                  placement="top"
                  arrow
                >
                  <Box component="span" className="living"></Box>
                </Tooltip>
              }

            </Typography>

            <FilterBscscan
              wallet={trackerWallet}
              filter={filter}
            />
          </Grid>
        }
      </Grid>

      {bot && tokenAddress !== "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" &&
        <React.Fragment>
          <Divider sx={{
            my: 2,
          }} />

          <WalletSwap
            info={info}
            wallet={trackerWallet}
            bnbBalance={bnbBalance}
            balance={balance}
            tokenPrice={tokenPrice}
            tokenAnalyse={tokenAnalyse}
          />
        </React.Fragment>
      }
    </Paper>
  );
}
