// Material UI
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Icons
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';

// Images
import DexScreenerLogo from 'images/DexScreenerLogo.svg';
import dexToolsLogo from 'images/dextools-logo.svg';
import pancakeswapCakeLogo from 'images/pancakeswap-cake-logo.svg';
import poocoinLogo from 'images/poocoin-logo.svg';

export default function Socials(props) {
  const {
    buttonBackground,
    buttonBackgroundHover,
    buttonColor,
    buttonColorHover,
  } = props;

  return (
    <Box sx={{
      "& .MuiButtonBase-root": {
        width: 30,
        height: 30,
        transition: "all .3s ease",
        color: buttonColor,
        backgroundColor: buttonBackground,
        mr: 2,

        '&:hover': {
          color: buttonColorHover,
          background: buttonBackgroundHover,
        },

        '& img': {
          height: '100%',
        },

        '&:last-child': {
          mr: 0,
        },
      },
    }}>
      <Tooltip
        title="Go to Twitter"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://twitter.com/ProfitAI_BSC`}
        >
          <TwitterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Go to Telegram Colored Cats Chat"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://t.me/ProfitAI`}
        >
          <TelegramIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Buy on PancakeSwap"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://pancakeswap.finance/swap?outputCurrency=0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32`}
        >
          <CardMedia
            component="img"
            src={pancakeswapCakeLogo}
            alt="Pancakeswap Cake Logo"
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Chart on DexScreener"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://dexscreener.com/bsc/0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32`}
        >
          <CardMedia
            component="img"
            src={DexScreenerLogo}
            alt="DexScreener Logo"
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Chart on DexTools"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x5bc1e875f85f260f18521139a8a11eaebdf9f801`}
        >
          <CardMedia
            component="img"
            src={dexToolsLogo}
            alt="DexTools Logo"
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Chart on PooCoin"
        placement="top"
        arrow
      >
        <IconButton
          target="_blank"
          href={`https://poocoin.app/tokens/0xb22a5ed00fFe067bC07AB153eF17793cD69D6E32`}
        >
          <CardMedia
            component="img"
            src={poocoinLogo}
            alt="PooCoin Logo"
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}