// Packages
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';

// Material UI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Store
import {
  selectBnbPrice,
} from 'app/bnbSlice';

// Library
import {
  formatDate,
  formatToken,
} from 'libraries/Common';
import {
  getBnbBalance,
  isBscTokenAddress,
} from 'libraries/Web3';

// Components
import ShowPrivateKey from 'components/pages/functions/bot-wallet/ShowPrivateKey';



export default function BotWalletItem(props) {
  const bnbPrice = useSelector(selectBnbPrice);

  const {
    botWallet,
  } = props;

  const time = (new Date(botWallet.date)).getTime() / 1000;



  const [fetching, setFetching] = useState();
  const [balance, setBalance] = useState(0);

  // declare the data fetching function
  const fetchData = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      try {
        // Get balance
        const address = await isBscTokenAddress(botWallet.botWalletAddress);
        if (address) {
          const bnbBalance = await getBnbBalance(botWallet.botWalletAddress);
          setBalance(bnbBalance);
        }
      }
      catch (error) {
        console.error(error);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0
        },

        '& th, & td': {
          verticalAlign: 'top',
        },
      }}
    >
      <TableCell component="th" scope="row" align-="center" sx={{
        whiteSpace: 'nowrap',
      }}>
        <ShowPrivateKey
          botWallet={botWallet}
        />
      </TableCell>
      <TableCell>
        <Tooltip
          title={botWallet.botWalletAddress}
          placement="top"
          arrow
        >
          <Typography sx={{
            display: 'inline-block',
          }}>
            {botWallet.botWalletAddress}
          </Typography>
        </Tooltip>

        <Typography sx={{
          '& span': {
            color: "#77838f",
            fontSize: 11,
          },
        }}>
          {botWallet.description &&
            <React.Fragment>
              <Typography variant="span">
                {botWallet.description}
              </Typography><br />
            </React.Fragment>
          }
          <Typography variant="span">
            Added on {formatDate(time)}
          </Typography>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={{
          '& span': {
            color: "#77838f",
            fontSize: 11,
          },
        }}>
          {balance === 0 &&
            <React.Fragment>
              0.000 BNB <Typography variant="span">($0.00)</Typography>
            </React.Fragment>
          }
          {balance !== 0 &&
            <React.Fragment>
              {formatToken(balance, 4, 4)} BNB <Typography variant="span">(${formatToken(balance * bnbPrice, 2, 2)})</Typography>
            </React.Fragment>
          }
        </Typography>
      </TableCell>
    </TableRow>
  );
}
