// Pai Bot Wallet GET
export async function PaiBotWalletsGet(walletAddress, signedData) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-bot-wallets`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: walletAddress,
        signedData: signedData,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// Pai Bot Wallet TRADE
export async function PaiBotWalletsTrade(walletAddress, signedData, method, botWalletAddress, tokenAddress, amount, customizedGasLimit) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-bot-wallets/trade`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: method,
        walletAddress: walletAddress,
        signedData: signedData,
        botWalletAddress: botWalletAddress,
        tokenAddress: tokenAddress,
        amount: amount,
        customizedGasLimit: customizedGasLimit,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
