// SafuCheck
export async function SafuCheck(tokenAddress) {
  const
    url = `https://apespace.io/api/audit?chain=bsc&address=${tokenAddress}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// SafuCheckGoPlus
export async function SafuCheckGoPlus(tokenAddress) {
  const
    url = `https://api.gopluslabs.io/api/v1/token_security/56?contract_addresses=${tokenAddress}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
