// PAI MEME CONTEST GET
export async function PAIMemeContestsGet(opt) {
  const
    filter = encodeURIComponent(JSON.stringify(opt)),
    url = `${process.env.REACT_APP_API_URL}pai-meme-contests?filter=${filter}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

// PAI MEME CONTEST VOTE
export async function PAIMemeContestsVote(id, walletAddress, signedData) {
  const
    url = `${process.env.REACT_APP_API_URL}pai-meme-contests/vote`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        walletAddress: walletAddress,
        signedData: signedData,
      }),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}
