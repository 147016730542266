// PAI Profit GET
export async function PAIProfitsGet(opt) {
  const
    filter = encodeURIComponent(JSON.stringify(opt)),
    url = `${process.env.REACT_APP_API_URL}pai-profits?filter=${filter}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}