// Packages
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

// Icons
import AddBoxIcon from '@mui/icons-material/AddBox';

// React Hook Form Mui
import {
  FormContainer,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';

// Store
import {
  selectAddress,
  selectSignedData,
} from 'app/connectedWalletSlice';

// Services
import {
  PaiWalletTrackingsPost,
} from 'services/PaiWalletTrackings';

// Library
import {
  isBscTokenAddress,
} from 'libraries/Web3';



export default function Add(props) {
  const {
    _getPermission,
    fetchWalletTrackings,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const connectedWalletAddress = useSelector(selectAddress);
  const connectedWalletSignedData = useSelector(selectSignedData);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fetching, setFetching] = useState();

  const onSubmit = async data => {
    const address = await isBscTokenAddress(data.trackingWalletAddress);

    if (!address) {
      enqueueSnackbar('Invalid Address', { variant: 'warning' });
      return false;
    }

    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const
        PAIWalletTracking = await PaiWalletTrackingsPost(connectedWalletAddress, connectedWalletSignedData, data);

      if (PAIWalletTracking.error && PAIWalletTracking.error.message) {
        // Catch error and set error message
        console.error(PAIWalletTracking.error);
        enqueueSnackbar(PAIWalletTracking.error.message, { variant: 'warning' });
      } else if (PAIWalletTracking.success && PAIWalletTracking.success.message) {
        enqueueSnackbar(PAIWalletTracking.success.message);
        await fetchWalletTrackings();
      }

      // Turn off fetching
      setFetching(false);

      // Close the popup
      handleClose();
    }
  }



  return (
    <React.Fragment>
      <Button variant="contained" size="small" startIcon={<AddBoxIcon />} sx={{
        ml: 1,
      }}
        onClick={handleOpen}
        disabled={!_getPermission.result}
      >
        Add
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Add a new wallet tracking address
        </DialogTitle>

        <FormContainer
          defaultValues={{ notification: true }}
          onSuccess={onSubmit}
        >
          <DialogContent sx={{
            '& > :not(style)': {
              mt: 2,
            },
          }}>

            <TextFieldElement fullWidth size="small" name="trackingWalletAddress" label="Address" required autoFocus />
            <TextFieldElement fullWidth size="small" name="description" label="Description" placeholder="Optional" />
            <SwitchElement name="notification" label="Notification" />

            <Divider />

            <SwitchElement name="copyBuyEnable" label="Copy buy enabled" />
            <TextFieldElement fullWidth size="small" name="copyBuyAmount" label="Buy amount" placeholder="0.01" />

            <Divider />

            <SwitchElement name="copySellEnable" label="Copy sell enabled" />

          </DialogContent>
          <DialogActions sx={{
            m: 2,
          }}>
            <Button onClick={handleClose} size="small">
              Cancel
            </Button>
            <Button variant="contained" size="small" type="submit" disabled={fetching}>
              Continue
            </Button>
          </DialogActions>
        </FormContainer>

      </Dialog>
    </React.Fragment>
  );
}
