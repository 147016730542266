// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function NotFound() {
  return (
    <Box component="main">
      <Typography variant="h2" color="primary.main" sx={{
        textAlign: 'center',
        py: 6,
      }}>
        404: Not Found
      </Typography>
    </Box>
  );
}
